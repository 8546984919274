@font-face {
    font-family: 'icomoon';
    src:  url('../../../public/fonts/icomoon/icomoon.eot?aflyam');
    src:  url('../../../public/fonts/icomoon/icomoon.eot?aflyam#iefix') format('embedded-opentype'),
    url('../../../public/fonts/icomoon/icomoon.ttf?aflyam') format('truetype'),
    url('../../../public/fonts/icomoon/icomoon.woff?aflyam') format('woff'),
    url('../../../public/fonts/icomoon/icomoon.svg?aflyam#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="mc-icon-"], [class*=" mc-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.mc-icon-tracking:before {
    content: "\e906";
}
.mc-icon-catalog:before {
    content: "\e900";
}
.mc-icon-world:before {
    content: "\e901";
}
.mc-icon-search:before {
    content: "\e902";
}
.mc-icon-pin:before {
    content: "\e903";
}
.mc-icon-account:before {
    content: "\e904";
}
.mc-icon-soccer:before {
    content: "\e905";
}
