@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/msp/menu';
@import '../../style/msp/mixin';

.McMenuFullScreen {

    &-Category-Name {
        text-transform: uppercase;
        font-family: Roboto, Regular, sans-serif;
        padding: 0 0 15px 0;
        font-weight: bold;

        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    &-Link {
        @include menu-link-style;
    }


    &-Text {
        @include menu-text-style;
    }

    &-Arrow {
        color: $mc__color__venetian-red;
    }

    &-Container {
        position: fixed;
        width: 100%;
        height: calc(100vh - var(--header-total-height));
        left: 0;
        top: var(--header-total-height);
        background: $white;
        overflow: hidden;
        padding-top: 20px;
    }

    &-Wrapper {
        max-width: var(--content-wrapper-width);
        padding: 0 1.2rem;
        height: 100%;
        display: flex;
        margin: auto;
        flex-direction: column;
    }

    &-Menu {
        max-width: 280px;
        flex: 0 0 280px;
        padding-right: 20px;

        // @include after-mobile-mondoconv {
        //     padding-left:15px;
        // }

        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 0.5rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid #708090;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &>ul {


            display: flex;
            align-items: flex-start;
            flex-direction: column;
            padding: 0;
            opacity: 1;
            margin: 0;

            &>li {
                &::before {
                    content: none;
                }

                width: 100%;
                margin: 0 0 10px 0;
                padding: 0;
            }
        }

    }

    &-SubCategory {
        display: flex;
        align-items: center;
        justify-content: space-between;
        opacity: 1;

        .configuratoreCucine,
        .collection {
            width: 266px;
            height: 40px;
            background: #152C69 0% 0% no-repeat padding-box;
            border-radius: 24px;
            font: normal normal bold 13px/39px Roboto;
            color: #FFFFFF;
            display: block;
            text-align: center;
            text-decoration: none;
            cursor: pointer;
        }

        .collection {
            width: 186px;

            &.chair {
                padding: 0 10px;
                width: 200px;
            }
        }
    }

    &-SubMenu {
        padding: 10px 0 22px 0;

        &-Element {
            display: flex;
            flex-direction: row;
            position: relative;
        }

        &-Element:not(:first-child) {
            padding: 22px 0 0 0;
        }

        &-Element-Dash {
            width: 13px;
            border-top: 1px #333333 solid;
            top: 8px;
            margin: 0 10px 0 0;
        }

        &-Link,
        &-Text,
        a.McMenuFullScreen-SubMenu-Link {
            text-decoration: none;
            font-size: 13px;
            color: #0a0a0a;
            font-family: Roboto, Regular, sans-serif;
            font-weight: 500;

            :hover {
                text-decoration: none;
                color: #db230b;
            }
        }
    }


    &-List {
        flex: 1 1;
        overflow-y: scroll;
        margin-left: 1.2rem;
        margin-right: 1.2rem;
        margin-top: -40px;

        &::-webkit-scrollbar {
            width: 0.5rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid #708090;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        padding-top: 20px;
    }

    &-Content {
        display: flex;
        overflow-y: hidden;
        flex: 1 1 100%
    }

    &-Actions {
        min-height: 100px;
        flex: 0 0 100px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &-CloseBtn {
        background-image: url('./assets/close.svg');
        width: 60px;
        height: 60px;
        cursor: pointer;
    }
}


// NEW MENU
@include desktop {
    .McMenuFullScreen-Container_newMenu {
        .McMenuFullScreen-Actions {
            display: none;
        }

        .McMenuFullScreen-List {
            display: none;
        }

        width: auto;
        height: auto;
        box-shadow: 5px 5px 30px #00000033;
        border: 1px solid #E7E7E7;
        border-radius: 4px;
        left: auto;
        // padding: 20px 25px;
        padding: 30px 30px 20px;
        overflow: visible;
        transform: translateX(calc(-50% + 50px));

        @media (max-width: 1440px) {
            padding: 20px 30px 20px;
        }

        &:after {
            content: '';
            position: absolute;
            top: -8px;
            // left: 15px;
            left: calc(50% - 13px);
            height: 15px;
            width: 15px;
            transform: rotate(45deg);
            background: #fff 0% 0% no-repeat padding-box;
        }

        .McMenuFullScreen-SubCategory {
            justify-content: flex-start;
            gap: 5px;
            max-width: 240px;


            .collection {
                max-width: 186px;
                width: 100%;
                text-transform: capitalize;
                font-family: 'Montserrat';

                .McMenuFullScreen-Arrow {
                    border-color: #fff;
                    transform: rotate(45deg) translate(8px, -8px);
                }
            }

            .McMenuFullScreen-Text {
                font: normal normal bold 16px/19px Montserrat;
                color: #333333;
                white-space: nowrap;
            }
        }

        .McMenuFullScreen-Menu {
            max-width: 100%;
            flex: none;
            padding-right: 0;
            overflow-y: visible;

            >ul {
                display: block;
                grid-column-gap: 30px;
                columns: 2;

                >li {
                    break-inside: avoid;
                    margin: 0 0 20px 0;

                    &:hover {
                        .McMenuFullScreen-Arrow {
                            transform: rotate(45deg) translate(8px, -8px);
                        }
                    }
                }
            }
        }

        .McMenuFullScreen-Content {
            display: block;
            overflow-y: visible;
            flex: none;
        }

        .McMenuFullScreen-Arrow {
            display: inline-block;
            width: 9px;
            height: 9px;
            min-width: 9px;
            min-height: 9px;
            border-right: 2px solid #db230b;
            border-top: 2px solid #db230b;
            transform: rotate(45deg);
            transform-origin: center;
            transition: transform 0.5s ease;

            &::before {
                content: "";
            }
        }

        .McMenuFullScreen-SubMenu-Element-Dash {
            width: 0;
            border-top: none;
            top: 0;
            margin: 0;
        }

        .McMenuFullScreen-SubMenu {
            padding: 10px 0 0;
        }

        .McMenuFullScreen-SubMenu-Element {

            &:not(:last-child) {
                margin-bottom: 15px;

                @media (max-width: 1440px) {
                    margin-bottom: 10px;
                }
            }

            >a>span {
                font: normal normal normal 14px/18px Montserrat;
                color: #333333;
            }

            &:not(:first-child) {
                padding: 0;
            }
        }

        .McMenuFullScreen-Category-Name {
            font: normal normal bold 24px / 29px Montserrat;
            display: block;
            // margin-bottom: 10px;
            text-decoration: none;

            @media (max-width: 1440px) {
                padding: 0 0 10px 0;
            }
        }

        .McMenuFullScreen-Wrapper {
            padding: 0;
        }

        .McMenuFullScreen-SubCategory .configuratoreCucine {
            width: 200px;
            height: auto;
            background: #152C69 0% 0% no-repeat padding-box;
            color: #FFFFFF;
            display: block;
            text-decoration: none;
            cursor: pointer;
            border-radius: 14px;
            padding: 10px;
            font: normal normal bold 14px / 18px Montserrat;
            text-align: left;
            text-transform: capitalize;

            i.fa.fa-caret-right {
                display: inline-block;
                width: 9px;
                height: 9px;
                min-width: 9px;
                min-height: 9px;
                border-right: 2px solid #ffffff;
                border-top: 2px solid #ffffff;
                transform: rotate(45deg);
                transform-origin: center;
                transition: transform 0.5s ease;

                &::before {
                    content: "";
                }
            }
        }

        &.McMenuFullScreen-Container_bagni {
            transform: translateX(calc(-50% + 23px));
        }


        &.McMenuFullScreen-Container_promozioni {
            transform: translateX(calc(-50% + 70px));

            @media (min-width: 1200px) and (max-width: 1559px) {
                right: 3px;
                transform: none;

                &::after {
                    left: 180px;
                }
            }
        }

        &.McMenuFullScreen-Container_complementi {
            transform: translateX(calc(-50% + 50px));

            @media (min-width: 1200px) and (max-width: 1499px) {
                right: 3px;
                transform: none;

                &::after {
                    left: calc(50% - 8px);
                }
            }
        }
    }
}