@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/msp/mixin';
@import '../../style/abstract/variables';

:root {
    --header-promo-popup-height: 35px;

    @include mobile {
        --header-promo-popup-height: 70px;
        --header-nav-height: 121px;
    }

    --header-nav-height: 134px;
    --header-height: var(--header-nav-height);
    --header-total-height: var(--header-nav-height);
    --header-menu-height: 70px;
    --content-wrapper-width: 1440px;

    @include after-mobile-mondoconv {
        --header-total-height: calc(var(--header-height) + var(--offline-notice-height) + env(safe-area-inset-top) + 20px);
        --header-nav-height: 115px;
        --header-top-menu-height: 30px;
        --header-height: calc(var(--header-nav-height) + var(--header-top-menu-height));
    }

    @media (min-width: 1200px) and (max-width: 1919px) {
        --header-total-height: calc(var(--header-height) + var(--offline-notice-height) + env(safe-area-inset-top) + 20px);
        --header-nav-height: 88px;
        --header-top-menu-height: 25px;
        --header-height: calc(var(--header-nav-height) + var(--header-top-menu-height));
    }

    &.PromoPopUpVisible {
        --header-total-height: calc(var(--header-nav-height) + var(--header-promo-popup-height));

        @include after-mobile-mondoconv {
            --header-total-height: calc(var(--header-height) + var(--offline-notice-height) + var(--header-promo-popup-height) + env(safe-area-inset-top) + 20px);
        }
    }
}

@mixin button-invisible {
    opacity: 0;
    padding: 0;
    width: 0;
    pointer-events: none;
    overflow: hidden;
}

@mixin button-visible {
    opacity: 1;
    padding: 7.5px;
    pointer-events: all;
    overflow: visible;
}

@mixin logo-visible {
    opacity: 1;
    max-width: 100%;
    display: block;
}

.no_margin {

    &_main {
        margin-top: 0
    }
}

.servicesNavbar-hidingHeader {

    // position: fixed !important;
    // top: 0 !important;
    @media (min-width: 1200px) and (max-width: 1919px) {
        // top: 90px !important;
        top: calc(var(--header-nav-height) + var(--header-promo-popup-height)) !important;
        transition: all 0.3s ease-in-out;
    }
}

.Header {

    &-Button {

        &_type_back {
            @include before-mobile-mondoconv {
                order: -1;
            }

            @include before-desktop {
                order: -1;
            }
        }
    }

    &-Wrapper {
        margin-bottom: var(--header-total-height);
    }

    &-FaiDaTeImage {
        background-image: url('./assets/tracking.svg');
        background-repeat: no-repeat;
        object-fit: scale-down;
        width: 36px;
        height: 23px;
        border: none;
    }

    &-StoreLocatorImage {
        background-image: url('./assets/shops.svg');
        background-repeat: no-repeat;
        object-fit: scale-down;
        width: 18px;
        height: 23px;
        border: none;
    }

    &-ComeAcquistareImage {
        background-image: url('./assets/acquisto_icon.svg');
        background-repeat: no-repeat;
        object-fit: scale-down;
        width: 18px;
        height: 24.5px;
        border: none;
    }

    &-InfoImage {
        background-image: url('./assets/faq.svg');
        background-repeat: no-repeat;
        object-fit: scale-down;
        width: 23px;
        height: 23px;
        border: none;
    }

    &-AccountImage {
        background-image: url('./assets/profilo.svg');
        background-repeat: no-repeat;
        object-fit: scale-down;
        width: 25px;
        height: 23px;
        border: none;
    }

    &-MinicartImage {
        background-image: url('./assets/carrello.svg');
        background-repeat: no-repeat;
        object-fit: scale-down;
        width: 26px;
        height: 23px;
        border: none;
    }

    &-CatalogoImage {
        background-image: url('./assets/catalogo.svg');
        background-repeat: no-repeat;
        object-fit: scale-down;
        width: 26px;
        height: 23px;
        border: none;
    }

    .mc-icon {
        color: $mc__color__night-rider;
        font-size: 1.6rem;
    }

    &_name {
        &_menu_subcategory {
            .SearchField {
                flex: 1 1;
                order: 2;
                font-size: 1.1rem;
            }

            .Header-Button {
                &_type {
                    &_close {
                        order: 3;
                    }
                }
            }
        }
    }


    //&_name {
    //    &_popup {
    //        @include mobile {
    //            border-radius: 30px 30px 0 0 ;
    //            border: 1px solid $mc__color__grey66;
    //            background: $white;
    //        }
    //    }
    //
    //    &_menu,
    //    &_menu_subcategory {
    //        .Header{
    //            &-Nav {
    //                height: var(--header-menu-height);
    //            }
    //        }
    //        .Header-Button_type {
    //            &_close {
    //                @include before-mobile-mondoconv {
    //                    @include button-visible;
    //                    width: 57px;
    //
    //                }
    //            }
    //
    //            &_back {
    //                @include before-mobile-mondoconv {
    //                    @include button-visible;
    //                    width: 33px;
    //
    //                }
    //            }
    //
    //            &_menu {
    //                @include before-mobile-mondoconv {
    //                    @include button-invisible;
    //                }
    //            }
    //        }
    //    }
    //
    //}
    background-color: $white;
    border-bottom: none;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);

    &.Header_name_menu,
    &.Header_name_menu_subcategory {
        box-shadow: none;
    }

    &.Header_name_menu_subcategory.FixedElement-Top {

        .Menu-SignIn,
        .MyAccount-Welcome,
        .Header-MyAccount {
            display: none;
        }
    }

    &.Header.Header_name_default.FixedElement-Top {
        .Menu-SignIn {
            display: none;
        }
    }

    @include after-mobile-mondoconv {
        border: 0;
        height: var(--header-total-height);
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }

    &-Nav {
        z-index: 100;
        flex-wrap: wrap;
        padding: 0 1.2rem;

        @include mobile {
            padding: 0 1.6rem;
            overflow: hidden;
            padding-top: 5px;
        }

        p.MyAccount-Welcome {
            font: normal normal normal 12px/14px Montserrat;
            margin-bottom: 0;
            text-align: right;
        }

        button.Menu-SignIn {
            padding: 0px 20px;
            cursor: pointer;
            border: 1px solid #152C69;
            border-radius: 16px;
            color: #152C69;
            font-weight: 600;
            height: 30px;
            width: 120px;
        }

        .Area-Utente {
            position: relative;

            @media screen and (max-width: 767px) {
                display: none;
            }

            .Welcome-Button {
                padding: 15px 20px;
                border-radius: 20px;
                box-shadow: 0px 4px 15px 3px #00000029;
                font-weight: 600;
                background-color: #152C69;
                color: #fff;

                cursor: pointer;

                i.fa.fa-chevron-down {
                    margin-left: 10px;
                    margin-right: -10px;
                    transition: all 0.2s ease-in-out;
                }

                i.fa.fa-chevron-down.up {
                    transform: rotate(180deg);
                    transition: all 0.2s ease-in-out;
                }
            }

            .toggle {
                position: absolute;
                background-color: white;
                padding: 20px;
                right: 0%;
                border-radius: 30px;
                box-shadow: 0px 4px 15px 3px #00000029;
                top: calc(100% + 10px);
                min-width: 250px;
                z-index: 9;

                ul {
                    list-style: none;

                    li {
                        padding: 0;
                        margin: 0;
                        margin-bottom: 20px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &::before {
                            content: none;
                        }

                        a {
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 5px;
                            font: normal normal normal 14px/16px Montserrat;

                            &:hover {
                                color: #DB230B;
                                text-decoration: none;
                                font-weight: 600;
                                // span {
                                //     transform: scaleY(1.107);
                                // }
                            }

                            div {
                                background-repeat: no-repeat;
                                background-size: 20px;
                                width: 20px;
                                height: 20px;

                                &.aPersonale {
                                    background-image: url('./assets/iconeMenu/dashboard.png');
                                }

                                &.ordini {
                                    background-image: url('./assets/iconeMenu/orders.png');
                                }

                                &.dPersonali {
                                    background-image: url('./assets/iconeMenu/personal_data.png');
                                }

                                &.newsletter {
                                    background-image: url('./assets/iconeMenu/newsletter.png');
                                }

                                &.indirizzi {
                                    background-image: url('./assets/iconeMenu/address.png');
                                }

                                &.esc {
                                    background-image: url('./assets/iconeMenu/logout.png');
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &-LogoWrapper {
        position: relative;
        flex: 1 1;
        flex-wrap: nowrap;
        height: 52px;
        margin-top: 0;
        margin-bottom: 0;
        display: none;

        @include after-mobile-mondoconv {
            flex: none;
            width: 170px;
            margin-top: 30px;
            margin-bottom: 15px;
            overflow: visible;
        }

        @media (min-width: 1200px) and (max-width: 1919px) {
            flex: none;
            width: 116px;
            margin-top: 0;
            margin-bottom: 0;
            overflow: visible;
        }

        @include before-mobile-mondoconv {
            opacity: 0;
            max-width: 0;
        }

        &_isVisible {
            @include logo-visible;
        }

        .Image-Image {
            object-fit: contain;
        }
    }

    &-Link {
        text-align: center;
        color: $mc__color__night-rider;
        font-family: $mc__font-family__montserrat;
        font-size: 1.1rem;

        display: flex;
        flex-direction: column;
        align-items: center;

        @include mobile {
            justify-content: center;
        }

        &:hover,
        &:active,
        &:focus,
        &:visited {
            text-decoration: none;
        }

    }
    &-ComeAcquistare {
        margin-left: 11px;
        margin-right: -9px !important;
    }
    &-Info,
    &-FaiDaTe,
    &-ComeAcquistare,
    &-Catalogo,
    &-StoreLocator {
        margin-right: 0;

        &Wrapper {
            align-items: center;
            display: flex;

            @include mobile {
                justify-content: center;
            }
        }

        &Title {
            cursor: pointer;
            text-transform: initial;
            color: $mc__color__night-rider;
            margin: .6rem 0 0;
            min-width: 6.5rem;
            text-align: center;

            @media screen and (max-width: 767px) {
                padding-right: .56rem;
                display: none;
            }
        }

        &Title_isVariant {
            color: $mc__color__night-rider;
        }

        &_isVariant {
            .mc-icon {
                color: white;
            }
        }

        &Icon {
            @media screen and (max-width: 767px) {
                height: 29px;
                width: 29px;
            }
        }
    }
    &-ComeAcquistare {
        &Title {
            margin-top:calc(.6rem - 0.9px);
        }
    }

    &-BurgerMenu {
        margin: 7px 15px;
    }

    &-BurgerIcon {
        background: url('./assets/menu.svg');
        width: 32px;
        height: 25px;
    }

    &-MyAccount {
        margin-right: 0;

        &Wrapper {
            align-items: center;
            display: flex;
            flex-direction: column;

            @media screen and (max-width: 767px) {
                justify-content: center;
                margin-left: 15px;
                height: 25px;
                width: 23px;
            }
        }

        &Title {
            cursor: pointer;
            text-transform: initial;
            margin: .6rem 0 0;
            color: $mc__color__night-rider;
            font-size: 1.1rem;
            padding-right: 0;
            order: 2;
            min-width: 6.5rem;
            text-align: center;
        }

    }

    &-Minicart {
        margin-right: 0;

        &ButtonWrapper {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: auto;
            margin: 0;
            padding: 0;

            @include mobile {
                justify-content: center;
            }
        }

        &Icon {
            height: auto;
            width: auto;
            top: 0;

            @media screen and (max-width: 767px) {
                height: 29px;
                width: 29px;
                top: auto;
            }

            &::before {
                font: normal normal normal 14px/1 FontAwesome;
                content: "\f07a";
                border: 0;
                color: $mc__color__night-rider;
                font-size: 1.6rem;
                top: 0;
                left: 0;
                position: relative;

                @include mobile {
                    transform: translate(-50%, -50%) rotate(0);
                    top: 8px;
                }
            }

            &::after {
                display: none;
            }
        }

        &Title {
            cursor: pointer;
            text-transform: initial;
            color: $mc__color__night-rider;
            font-size: 1.1rem;
            padding-right: 0;
            margin: .6rem 0 0;
            order: 2;
            min-width: 6.5rem;
            text-align: center;

            @media screen and (max-width: 767px) {
                padding-right: .56rem;
                display: none;
            }
        }
    }

    &-Button {

        &_type {
            &_searchMobile,
            &_minicart {
                padding: 0;

                @include mobile {

                    margin-left: 15px;
                    width: 25px;
                    height: 23px;
                    margin-right: 10px;

                    &::before,
                    &:after {
                        content: none;
                    }
                }
            }

            &_searchMobile {
                width: 20px;
                height: 20px;

                .CustomSearchBar-Icon {
                    height: 20px;
                    width: 20px;
                }

                path {
                    fill: #4f4e4e;
                }
            }

            &_account {
                padding: 0;

                @include mobile {

                    margin-left: 15px;
                    height: 25px;
                    width: 23px;
                }

                &::before,
                &::after {
                    content: none;
                }

            }

            &_close {

                // Non so se la X è sempre la stessa in tutto il sito,
                // se fosse la stessa in tutto il sito bisognare spostare questa riga qui sotto
                @include before-mobile-mondoconv {
                    width: 57px;
                    height: 57px;

                    &::before {
                        background-image: url('./assets/X.svg');
                        background-repeat: no-repeat;
                        width: 57px;
                        height: 57px;
                        top: 0;
                        left: 0;
                        border: none;
                        transform: none;
                        background-color: transparent;
                    }

                    &::after {
                        display: none;
                    }
                }

                order: 2;
            }

            &_back {

                @include before-mobile-mondoconv {
                    width: 33px;
                    height: 27px;

                    &::before {
                        order: -1;
                        background-image: url('./assets/freccia.svg');
                        background-repeat: no-repeat;
                        width: 33px;
                        height: 27px;
                        top: 0;
                        left: 0;
                        border: none;
                        transform: none;
                        background-color: transparent;
                    }

                    &::after {
                        display: none;
                    }
                }
            }
        }

        &-isVisible {
            @include before-mobile-mondoconv {
                @include button-visible;
            }
        }
    }

    &-MinicartItemCount {
        background: $mc__color__venetian-red;
        top: -8px;

        @include after-mobile {
            top: -.8rem;
            left: auto;
            right: -2rem;
            border-radius: 14px;
            line-height: 11px;
            min-width: 10px;
            padding: 3px 4px 1px;
            font-size: 1.1rem;
        }

        @include mobile {
            min-width: 17px;
            min-height: 17px;
            line-height: 12px;
        }
    }

    &-Title_isVisible {
        text-transform: uppercase;
        font-size: 2.7rem;
        height: 2.7rem;
        line-height: 2.7rem;
        font-weight: $mc__font-weight__lighter;
        font-family: $mc__font-family__montserrat;
    }

    &_name {

        &_customer_account,
        &_customer_sub_account {

            .Header-Button_type_account,
            .Header-MyAccountTitle {
                margin: .6rem 0 0;
            }
        }
    }


    @include mobile {
        transition: top 1s;
        &:not(.scrollDown) {
            position: relative;
            top: -20%;

            .Header-Button.Header-Button_isVisible.Header-Button_type_searchMobile {
                width: 0;
                opacity: 0;
            }
        }

        &-Wrapper {
            margin-bottom: 0 !important;
        }

        &.showSearch {
            &.scrollDown {
                .Header-Nav {
                    height: calc(var(--header-nav-height));
                    transition: all 0.2s;
                }

                .Header-Button_type_searchMobile {
                    display: none;
                }
            }
        }

        &.scrollDown:not(.showSearch) {
            .SearchField {
                margin-top: 30px;
            }

            .Header-Nav {
                padding-top: 10px;
            }
        }
    }
}

.PromoPopUpVisible {
    .Header {
        padding-top: $mc__promo_popup_height;

        @include mobile {
            padding-top: $mc__promo_popup_height_mobile;
        }
    }
}

.no_margin {
    // &_wrapper {
    //     margin-bottom: 0;
    // }

    &_main {
        margin-top: 0
    }
}

.scroll {
    &Down {
        @include mobile {
            position: fixed;
            // transform: translate(0, -100%);
            transition: top 1s;
        }

        @media (min-width: 891px) and (max-width: 1919px) {
            transition: all 0.4s ease-in-out;
            --header-total-height: var(--header-nav-height) + var(--header-promo-popup-height);
        }
    }

    &Up {
        @include mobile {
            position: fixed;
            transition: all 0.7s ease-in-out;

            // &.istant {
            //     transition: all 0.1s ease-in-out;
            // }
        }

        @media (min-width: 891px) and (max-width: 1919px) {
            transition: all 0.3s ease-in-out;
        }
    }
}

@media screen and (max-width: 810px) and (min-width: 767px) {
    .Header-MyAccountTitle {
        padding-right: unset !important;
        display: unset !important;
    }

    .Header-Button_type_minicart {
        margin-left: unset;
        width: unset;
        height: unset;
    }
}



// BLACK FRIDAY STYLE
body.darkTheme {
    background-color: #000;

    .Header {
        background-color: #000;

        .McMenuItem .McMenuItem-Text.McMenuItem-Text_isMainMenu {
            color: #fff;
        }
    }

    .Header-InfoWrapper,
    .Header-FaiDaTeWrapper,
    .Header-CatalogoWrapper,
    .Header-MyAccount,
    .Header-StoreLocatorIcon {
        filter: brightness(0) invert(1);
    }

    .Header-MinicartButtonWrapper {

        .Header-MinicartTitle,
        .Header-MinicartImage {
            filter: brightness(0) invert(1);
        }
    }

    span.McMenuItem-Text_isOfferte:hover {
        background-color: #000;
    }

    .CustomSearchBar-Input {
        background-color: #000;
        color: #fff;
        border: 2px solid #ffffff;

        &::placeholder {
            color: #fff;
        }

        &:focus {
            background: #000000;
            border: 2px solid #ffffff;
        }
    }

    svg.CustomSearchBar-Icon,
    button.CustomSearchBar-Reset {
        filter: brightness(0) invert(1);
    }

    .Image.Logo {
        background-color: transparent;
    }

    .HomePage {
        background-color: #000;

        .home-product-slider h2 {
            color: #fff !important;
        }

        .ProductSliderCard-SlideInfo {
            p.ProductPrice {
                color: #fff;
            }
        }

        .ProductSliderCard-SlideDescription {

            a,
            p {
                color: #fff;
            }
        }

        .ProductSliderCard-SlideDetail {

            div,
            h3 {
                color: #fff !important;
            }
        }
    }

    .Footer-Content,
    .Footer-AltroContentWrapper,
    .Footer-CopyrightContentWrapper {
        background-color: #000;
    }
    .NewsletterCustom {
        border-bottom: 8px solid #db230b;
        background-color: black;
        .title {
            color: white;
        }
        .content .info {
            color: white;
        }
    }
    .NewsletterCustom-Checkbox>label>div {
        color: white;
    }
    .PrivacyCheckbox-Link {
        color: white;
    }


    // TEST MIGLIOR PREZZO
    .CategoryPage-ActionBar .CategoryPage-LayoutIcon {
        filter: invert(1);
    }
    .CategoryPage-OrderByToggle span,
    .CategoryPage-OrderByToggle i {
        color: white;
    }
    .ProductCard {
        .ProductCardDetails-Name {
            color: white;
        }
        .ProductCardDetails-InlineDescription,
        .ProductCardDetails-Finish,
        .ProductPrice-CurrentPrice,
        a,i  {
            color: white;
        }
    }
    .Header-BurgerIcon {
        filter: grayscale(100%) brightness(1000%);
    }
    .CategoryPaginationLink:not(.CategoryPaginationLink_isCurrent), .CategoryPagination-Icon{
        color: white;
    }
    .Header-Nav .Logo img {
        content: url('https://mondoconv.it/media/logo/stores/default/logo-bf.png');
    }
}

.Header-Button[aria-hidden="true"] {
    display: none !important;
}

.Book {
    .Popup-Wrapper {
        border-radius: 44px;

        max-width: 1400px;
        // padding: 50px 80px;

        @media (max-width: 1399px) {
            max-width: 95%;
            padding: 20px 10px;
        }

        @media (max-width: 811px) {
            padding: 0;
        }
    }

    .Popup-CloseBtn {
        top: -4.5rem;
        right: -9rem;

        @media (max-width: 1399px) {
            top: -1.5rem;
            right: -3rem;
        }

        @media (max-width: 811px) {
            top: -2.5rem;
            right: -3.5rem;
        }
    }

    .Popup-Body {
        max-width: 1400px;
        padding: 50px 80px;

        @media (max-width: 1399px) {
            max-width: 95%;
            padding: 20px 10px;
        }
    }

    .title {
        text-align: center;
        font: normal normal bold 45px/30px Montserrat;
        color: #1D2E66;
        margin-bottom: 20px;
    }

    .subTitle {
        text-align: center;
        font: normal normal normal 21px/30px Montserrat;
        color: #1D2E66;
        margin-bottom: 30px;
    }

    .mc-container p,
    a {
        color: #333333;
        line-height: 1rem;
        margin: 0 !important;
    }

    .mc-container a:hover {
        text-decoration: none !important;
        color: #333333 !important;
    }

    .mc-img-link {
        display: block;
        width: 100%;
        height: 100%;
    }

    .mc-img-link img {
        width: 100%;
        height: auto;
    }

    .mc-img-link .mc-img-desk {
        display: none;
    }

    .mc-img-link .mc-img-mob {
        display: block;
    }

    .mc-card {
        background-color: #ffffff;
        margin-bottom: 50px;
    }

    .mc-card-text {
        min-height: 110px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .mc-card-text p {
        font-size: 10px;
    }

    .mc-card-link {
        display: flex;
        align-items: center;
    }

    .mc-card-link:nth-of-type(2) {
        margin-top: 3px;
    }

    .mc-card-link a {
        font: normal normal bold 12px/21px Montserrat;
        color: #333;
        margin: 0 6px 0 0;

        @include mobile {
            color: #DB230B;
        }
    }

    .mc-card-link img {
        width: 20px;
        height: 12px;
    }

    .mc-card-link img {
        margin: auto 5px;
        width: 100%;
        max-width: 10px;
    }

    .mc-card-text-header h2 {
        margin: 5px 0 3px 0;
        text-align: left;
        color: #333333;
        font: normal normal bold 30px/37px Montserrat;
        text-transform: none;
    }

    .mc-card-text-header p {
        text-align: left;
        font: normal normal normal 12px/15px Montserrat;
        color: #333333;
        margin-bottom: 20px;
    }

    .row.no-gutters {
        justify-content: space-between;
        align-items: center;
    }

    @media screen and (min-width: 812px) {
        .mc-header-text-h2 {
            font-size: 21px;
        }

        .mc-card-text-header h2 {
            margin: 10px 0 3px 0;
            text-align: left;
            color: #333333;
            font: normal normal bold 30px/37px Montserrat;
        }

        .mc-card-text-header p {
            text-align: left;
            font: normal normal normal 16px/19px Montserrat;
            color: #333333;
            margin-bottom: 20px;
        }

        .mc-card-link {
            margin-bottom: 10px;
        }

        .mc-img-link img {
            width: 100%;
            height: auto;
        }

        .mc-img-link .mc-img-desk {
            display: block;
        }

        .mc-img-link .mc-img-mob {
            display: none;
        }

        .mc-card {
            margin-bottom: 20px;
        }

        .mc-card-text {
            min-height: 150px;
            justify-content: space-between;
        }

        .mc-card-link a {
            font: normal normal bold 18px/22px Montserrat;
        }

        .mc-card-link:nth-of-type(2) {
            margin-top: 0;
        }

        .mc-card-link-group {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
        }
    }

    @media screen and (max-width: 811px) {
        .mc-card-text {
            padding-left: 15px;
        }

        .title {
            font: normal normal bold 25px/30px Montserrat;
            margin-bottom: 10px;
            margin-top: 30px;
        }

        .subTitle {
            font: normal normal normal 14px/19px Montserrat;
            margin-bottom: 10px;
        }

        .mc-card-text-header h2 {
            font: normal normal bold 21px/25px Montserrat;
        }

        .mc-card-text-header p {
            font: normal normal normal 12px / 15px Montserrat;
            margin-bottom: 10px;
        }

        .mc-card {
            margin-bottom: 30px;
        }
    }
}

// NEW MENU

.Header {
    z-index: 99999;
}