@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.NoMatch {
    padding: 30px 0;
    @include mobile {
        min-height: inherit
    }
    h1 {
        font-family: Yeseva One;
        font-size: 48px;
        font-weight: 400;
        line-height: 48px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #152C69;
        max-width: 959px;
        margin: 25px 0;

        @include mobile {
            font-family: Yeseva One;
            font-size: 35px;
            font-weight: 400;
            line-height: 40px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;

        }
    }

    img {
        max-width: 480px;

        @include mobile {
            max-width: 317px;
        }
    }

    p {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        @include mobile {
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 500;
            line-height: 14.63px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
        }
    }

    a {
        width: 286px;
        height: 48px;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 700;
        line-height: 17.07px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;


        @include mobile {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 700;
            line-height: 17.07px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            width: 306px;
            height: 48px;

        }


    }
}