@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
:root {
    --menu-item-figure-background: white;
}

.Menu {
    &-CompareLinkWrapper {
        display: none;
    }

    &-ItemFigure_type_main {
        @include mobile {
            height: 40px;
            padding-left: 15px;
            font-size: 2rem;
            font-weight: $mc__font-weight__regular;
        }
    }

    &-ItemCaption {
        font-size: 1.3rem;
        font-weight: $mc__font-weight__regular;
        text-transform: uppercase;

        &:hover {
            border-bottom: 2px solid;        
        }
    }

    &-Item {
        @include mobile {
            margin-top: 0;
            margin-bottom: 0;
            border-bottom: 1px solid $mc__color__gainsboro;

            > .Menu-SubCatLink {
                cursor: pointer;

                &::after {
                    font: normal normal normal 14px/1 FontAwesome;
                    content: "\f105";
                    color: $mc__color__night-rider;
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 3.2rem;
                }
            }
        }
    }

    &-MainCategories {
        z-index:10;
    }
}