@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/msp/mixin';

    .AlgoliaResults {
        background: white;
        display: flex;
        margin-top:10px;
        flex-direction: row;
        border-style: solid;
        border-width: 3px 1px 1px 1px;
        border-color: #bbbbbb;
        border-top-color: $mc__color__white-smoke;
        width: 100%;
        max-height: calc(100vh - 165px);

        // @include after-mobile-mondoconv {
        //     width: 130%;
        // }

        @include before-mobile-mondoconv {
            margin-top:0;
            position: fixed;
            width: 100vw;
            height: calc(100vh - var(--header-height));
            left: 0;
            top: var(--header-height);
            border-width: 1px 0 0 0 ;
            overflow-y: scroll;
            flex-direction: column;
        }
        @include mobile {
            // top: 0;
            height: 100vh;
            max-height: 100vh;
            z-index: 9999;
        }
        a {
            background: white;
        }

        a:hover {
            text-decoration: none;
            background: #f0f0f0;
        }

        .ais-Highlight-highlighted {
            font-weight: bold;
        }

        &-showAll {
            font-size: 12px;
            display: block;
            text-align: center;
            background: $white;
            padding: 7px;
            width: 100%;
            color: $mc__color__night-rider;

            &:hover {
                background: $white;
                color: $mc__color__night-rider;
            }
        }

        &-notFound {
            text-align: left;
            color: #aaaaaa;
            padding: 0 12px 12px 12px;
        }

        &-heading {
            text-align: left;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 500;
            color: #666666;
            padding: 12px;
        }

        .ais-Hits {
            padding: 0 12px 12px 12px;
            flex: 1;

            &-list {
                display: flex;
                height: 100%;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: baseline;
            }

            &-item {
                padding-left: 0;

                @include after-mobile-mondoconv {
                    flex: 1 1 50%;
                }

                @include before-mobile-mondoconv {
                    flex: 1 1 50%;
                }

                @include mobile {
                    flex: 1 1 100%;
                }
            }
        }

        &-categoriesResults,
        &-pagesResults {
            .ais-Hits {
                &-item {
                    flex: 1 1 100%;
                }
            }
        }

        &-leftCol {
            border-right: 1px solid #eeeeee;
            display: flex;
            flex-direction: column;
            width: 500px;
            overflow-y: auto;

            @include before-mobile-mondoconv  {
                order: 2;
            }
        }

        &-rightCol {
            flex-grow: 1;
            overflow-y: auto;

            @include before-mobile-mondoconv  {
                order: 1;
                flex-grow: 0;
            }
        }

        &-productsResults {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .SearchResultProduct-originalPrice {
            display: none;
        }
    }
