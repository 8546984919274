@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.NewsletterSubscription {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 290px;
    background: #a8a8a8;

    &-Content {
        padding: {
            top: 70px;
            bottom: 70px;
        }
        background: #a8a8a8;

        &::before {
            position: absolute;
            content: "%";
            font-size: 150px;
            font-family: $mc__font-family__raleway;
            font-weight: $mc__font-weight__lighter;
            color: #ffffff;
            left: -25%;
            opacity: 0.2;
        }
    }
}