@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/msp/menu';
@import '../../style/msp/mixin';

.McMenuItem {
    &::before {
        content: none;
    }

    display: flex;
    align-items: center;
    padding:0;
    height: 100%;
    opacity: 1;
    margin-bottom: 0;

    &:last-child {
        margin-bottom: 0;
    }


    &-Link {
        @include menu-link-style;
    }


    &-Text {
        @include menu-text-style;
    }

}

// NEW MENU

.McMenuItem {
    &-Text_isActive {
        border-bottom: 3px solid #E30613;
    }
}