$mc__catalog__column__desktop :0.166667;
$mc__catalog__column__mobile : 0.8;

:root {
    --app-height: 100%;
}

.buttonContainer {
    display: flex;
    justify-content: space-around;
    padding: 20px 0;
    background: $mc__grey__background-color;
}


.Menu-ItemList_type_main {
    @include desktop {
        top: 80px !important;
    }
}

.Menu-MenuWrapper {
    background: transparent;
}

.catalog-banners {
    flex: 0 0 percentage($mc__catalog__column__desktop);
    max-width: percentage($mc__catalog__column__desktop);

    @include mobile {
        flex: 0 0 percentage($mc__catalog__column__mobile);
        max-width: percentage($mc__catalog__column__mobile);
    }

    a {
        text-align: center;
        display: block;
    }
    &:hover a {
        transition: all 300ms ease-in-out;
        box-shadow: 5px 5px 20px rgba(0,0,0,0.25);
        height: 98%;
    }
}

.catalog-banners {
    transition: all 300ms ease-in-out;

    a {
        box-shadow: 5px 5px 20px rgba(0,0,0,0.2);
        font-size: 0;
    }
    &:hover {
        transform: translate(-5px,-5px);
        a {
            transition: all 300ms ease-in-out;
            box-shadow: 5px 5px 20px rgba(0,0,0,0.25);
            height: 100%;
        }
        a::after {
            transform-origin: right bottom;
            transform: scale(1);

            img {
            }
        }
    }
    a {
        text-align: center;
        display: block;
        &::after {
            // content: url('../../public/img/catalog-page-curl.png');
            content: url('../../../public/img/catalog-page-curl.png');
            position: absolute;
            bottom: 0;
            right: 0;
            transition: transform 300ms ease-in-out;
            transform: scale(0);
            transform-origin: right bottom;
        }
        img {
            width: 100%;
            box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.20);
        }
        .Image_ratio_square {
            padding-bottom: 129%;
        }
    }
}

/**
* Images
**/

.img--1920-960,
.img--960-480 {
    .Image_ratio_square {
        padding-bottom: 50%;
    }
}

/**
* mi server per nascondere la scrollbar orizzontale dovuta
* al menu di navigazione delle categorie
**/
html {
    overflow-x: hidden;
}
