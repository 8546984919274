.CategoryPage {

    @include before-mobile-mondoconv {
        margin-top: 13rem;
    }

    @include mobile {
        margin-top: 0;
    }

    &-ItemsCount {
        display: none;
    }

    &-Wrapper.ContentWrapper {
        max-width: $mc__width__category;
    }

    &-Miscellaneous {
        display: none !important; // FIXME
    }

    &-Banner {
        padding: 0 1rem;
    }

    &-Wrapper {
        padding-top: 0 !important; // FIXME
        position: static;
    }
    .CategoryProductList-Page{
        
        &_layout{

            &_one_column,
            &_two_column,
            &_three_column {
                .ProductCard {
                    flex-direction: column;

                    &:last-child {
                        margin-bottom: 6rem;
                
                        @include mobile {
                            margin-bottom: 0;
                        }
                    }
        
                    > .ProductCard-Link {
                        width: 100% !important; // FIXME
                        // @include before-mobile-mondoconv {
                            flex-direction: column;
                        // }
        
                        .ProductCard-Picture {
                            padding-bottom: 62.5%;
                        }
        
                        .ProductCard-Figure > .Image {
                            width: 100%;
                        }
        
                        .ProductCard-Figure > .Image-Image {
                            object-fit: initial;
                        }
                    }
        
                    .ProductActions-AddToCart {
                        width: 100%;
                        margin-right: 0;
                        @include after-mobile-mondoconv {
                            min-width: 250px;
                        }
                    }
        
                    &-Content {
                        flex-direction: row;
        
                        @include before-mobile-mondoconv {
                            flex-wrap: nowrap;
                        }
        
                        &_is2prod:not(.ProductCard-Content_hasMultipriceTitle) {
                            flex-direction: column;
                        }
                    }
        
                    &-Price {
                        @include before-mobile-mondoconv {
                            display: flex;
                            align-items: flex-end;
                        }
        
                        .ProductPrice-HighPrice {
                            line-height: 1.3em;
                        }
                    }
        
                    &-MainInfo {
                        @include before-mobile-mondoconv {
        
                        }
                    }
                }
            }

            &_one_column {

                .ProductCard {
                    flex: 0 0 100%;
                    max-width: 100%;
                }

                .McDimensioniPopup{
                    .Overlay{
                        left:0;
                    }
                }
            }
            &_two_column {

                .ProductCard {
                    flex: 0 0 50%;
                    max-width: 50%;
                }
                .McDimensioniPopup{
                    .Overlay{
                        right:0;
                    }
                }
            }
            &_three_column {

                .ProductCard {
                    flex: 0 0 33.333333%;
                    max-width: 33.333333%;
                }
                .McDimensioniPopup{
                    .Overlay{
                        right:0;
                    }
                }
            }
        }
    }

    .CategoryProductList {
        &-Page {
            grid-column-gap: 0;
            max-width: $mc__width__category;
            margin-right: -1.2rem;
            margin-left: -1.2rem;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            @include before-mobile-mondoconv {
                margin-right: 0;
                margin-left: 0;
            }
        }
        top: 3rem;
    }
}
.Image.promo {
    &-popup {
        padding-bottom:31%;
        margin: 40px 0 0 0;

        @include mobile {
            margin: 15px 0 0 0;
        }
    }
}
