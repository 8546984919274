.ProductCard-Name.ProductCard-Name_type_SecondLevel {
    font-size: 40px;
    line-height: 47px;
}

.ProductCard-mcInlineDescription.ProductCard-mcInlineDescription_type_SecondLevel {
    font-size: 21px;
    font-weight: $mc__font-weight__lighter;
    color: $mc__color__night-rider
}

.ProductCard-mcInlineDescription2.ProductCard-mcInlineDescription2_type_SecondLevel {
    span, strong {
        display: inline;
        font-size: 1.3rem;
    }
}

.ProductCard-mcEtichettaFinitura.ProductCard-mcEtichettaFinitura_type_SecondLevel {
    span, strong {
        display: inline;
        font-size: 1.3rem;
    }
}

.ProductCard-mcDimensioni.ProductCard-mcDimensioni_type_SecondLevel {
    span, strong {
        display: inline;
        font-size: 1.3rem;
    }
}

.ProductCard-LinkColori.ProductCard-LinkColori_type_SecondLevel {
    span {
        display: inline;
        font-size: 2.1rem;
        line-height: 3rem;
        font-family: $mc__font-family__montserrat;
        font-weight: $mc__font-weight__regular;
        font-style: italic;
        color: $mc__color__night-rider;
        text-decoration: none;
    }
}