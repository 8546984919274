@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import "../../style/msp/mixin";

.NewsletterCustom {
    align-items: center;
    background: #ffffff;
    border-top: 8px solid #db230b;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0;
    padding: 0;

    .title {
        color: #14356b;
        font-family: $mc__font-family__yeseva;
        font-size: 50px;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        letter-spacing: 0px;
        line-height: 55px;
        margin: 45px 0 0;
        text-align: left;
        width: 100%;
        @include mobile {
            text-align: center;
            font-size: 30px;
            line-height: 34px;
            margin-top: 25px;
        }
    }

    .content {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 58px;

        @include after-mobile-mondoconv {
            max-width: 910px;
        }
    }

    .content .info {
        color: #333333;
        font-family: $mc__font-family__montserrat;
        font-size: 21px;
        font-style: normal;
        font-variant: normal;
        font-weight: 600 !important;
        letter-spacing: 0;
        line-height: 26px;
        margin: 9px 0 15px;
        text-align: left;
        width: 100%;
        max-width: 100% !important;
        @include mobile {
            text-align: center;
            font-size: 15px;
            line-height: 19px;
        }
    }

    &-Checkbox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 15px;

        &_FooterNewsletter {
            margin: 0 0 15px;

            &:last-child {
                margin: 0 0 46px;
                @include mobile {
                    margin: 0 0 25px;
                }
            }
        }

        > label:first-of-type {
            align-items: center;
            color: #585858;
            display: flex;
            flex-direction: row-reverse;
            align-items: flex-start;
            font-family: $mc__font-family__montserrat;
            font-size: 12px;
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            letter-spacing: 0;
            line-height: 15px;
            text-align: left;
        }

        > label > div {
            margin: 0 18px;
        }

        + .input-control {
            margin-left: 0;
            margin-right: 1rem;
            min-height: 20px;
            min-width: 20px;
        }

        .Field-Message {
            color: #db230b;
            text-align: left;
        }
    }

    &-Link {
        text-decoration: underline;
    }

    &-Content {
        display: flex;
        flex-direction: column;
        align-items: center;
        &.neoNewsletter {
            flex-direction: row;
            max-width: 1444px;
            justify-content: space-between;
            padding: 0 50px;
            gap: 5%;
            @include mobile {
                flex-direction: column-reverse;
                gap: 0;
                padding: 0 25px;
            }
            > div:first-child {
                max-width: 700px;
                @include mobile {
                    padding: 0 15px;
                }
            }
            img {
                max-width: 600px;
                width: 50%;
                @include mobile {
                    width: 100%;
                    max-width: 400px;
                }
            }
        }
    }

    &-Group {
        display: flex;
        border: 2px solid #b1b1b1;
        border-radius: 24px 24px 24px 24px;
        background-color: $white;
        margin: 0 32px 0;
        // max-width: 583px;
        @include mobile {
            margin: 0;
        }
    }

    &-Input {
        flex: 1;
        margin: 0;
        height: 48px;
        width: 100%;

        input {
            border: none;
            color: #585858;
            font-family: $mc__font-family__montserrat;
            line-height: 14px;
            font-size: 12px;
            font-style: normal;
            font-variant: normal;
            font-weight: 300;
            letter-spacing: 0;
            border-radius: 24px;
        }

        .Field-Message {
            color: #db230b;
            margin-top: 4px;
            text-align: left;
        }
    }

    &-Button {
        background-color: #db230b;
        border-radius: 24px;
        color: #ffffff;
        font-family: $mc__font-family__montserrat;
        font-size: 14px;
        font-weight: bold;
        height: 48px;
        line-height: 18px;
        margin: 0;
        min-width: 142px;
        padding: 7px 15px;
        text-transform: uppercase;
        outline: 2px solid #db230b;
        &:hover {
            background-color: #ff1e00;
            outline: 2px solid #ff1e00;
        }
        @include mobile {
            min-width: 0;
        }
    }

    @media only screen and (min-width: 768px) {
        .content .info {
            max-width: 550px;
        }

        &-Group {
            margin: 0 auto 0 0;
        }
    }

    @media only screen and (min-width: 1024px) {
        &-Checkbox {
            > label:first-of-type {
                font-size: 14px;
                line-height: 18px;
            }
        }
        .title {
            font-size: 46px;
            line-height: 58px;
            margin: 88px 0 0;
        }

        .content {
            margin-bottom: 102px;
            max-width: 910px;
        }

        // .content .info {
        //     font-size: 22px;
        //     line-height: 30px;
        //     max-width: 910px;
        // }
    }
}
