@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.CmsBlock {
    border:0;
    &-Wrapper_type {
        &_Menu {
            display: flex;
            flex-wrap: wrap;
            margin-top:20px;
        }

        &_BlackFriday {
            background-color: $mc__color_blackfriday;
            .Image  {
                padding-bottom: 34%;
            }
        }

        &_Legal {
            h4 {
                margin-bottom: 20px;
            }
        
            ul {
                list-style: none;
        
                li {
                    &::before{
                        content: '';
                    };
                    margin-left: 0;
                    padding-left: 0;
                }
            }
        
            &-Icon{
                &::before {
                    color: $mc_color_green;
                    font-size: 3rem;
                }
            }
        }
    }

    &-Loader {
        color: transparent;
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
    }
}

// CmsBlock-Wrapper_type_Menu
