@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.McMenu {
    display: flex;
    margin: auto;
    max-width: var(--content-wrapper-width);
    max-height: 30px;
    height: var(--header-nav-height);
    transition: height .6s ease-in-out;
    align-items: center;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;

    &Item {
        .mc-menu-product__bottom {
            @media screen and (min-width: 1440px) {
                bottom: -5px;
                align-items: center;
            }

            .mc-menu-product__name {
                text-align: left
            }

            align-items: center;
        }
    }

    span.mc-menu-product__price {
        max-height: 20px;
    }
}


.scrollDown {
    .McMenu {
        @media (min-width: 1200px) and (max-width: 1919px) {
            --header-nav-height: 0;
            overflow: hidden;
            transition: height 0.3s ease-in-out;
        }
    }

    @include mobile {
        .Logo {
            width: 92px;
            height: 45px;
        }

        .Header-LogoWrapper {
            height: 45px;
        }


        .Header-Nav {
            height: calc(var(--header-nav-height) - 52px);
        }
    }
}