@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.TempiDiConsegnaPopup {
    h4 {
        margin-bottom: 20px;
    }

    ul {
        list-style: none;

        li {
            &::before{
                content: '';
            };
            margin-left: 0;
            padding-left: 0;
        }
    }

    &-Icon{
        &::before {
            color: $mc_color_green;
            font-size: 3rem;
        }
    }
}
