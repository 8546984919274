@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.PrivacyCheckbox {
    &-Checkbox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 15px;

        > label:first-of-type {
            align-items: center;
            color: #585858;
            display: flex;
            flex-direction: row-reverse;
            align-items: flex-start;
            font-family: $mc__font-family__montserrat;
            font-size: 12px;
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            letter-spacing: 0;
            line-height: 15px;
            text-align: left;
            margin-bottom: 10px;
        }

        > label > div {
            margin: 0 18px;
        }

        + .input-control {
            margin-left: 0;
            margin-right: 1rem;
            min-height: 20px;
            min-width: 20px;
        }

        .Field-Message {
            color: #db230b;
            text-align: left;
        }
    }

    &-Link {
        text-decoration: underline;
    }



}