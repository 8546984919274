@mixin hideScrollbar {
    // https://blogs.msdn.microsoft.com/kurlak/2013/11/03/hiding-vertical-scrollbars-with-pure-css-in-chrome-ie-6-firefox-opera-and-safari/
    // There is a CSS rule that can hide scrollbars in Webkit-based browsers (Chrome and Safari).
    &::-webkit-scrollbar {
      width: 0 !important
    }
    // There is a CSS rule that can hide scrollbars in IE 10+.
    -ms-overflow-style: none;

    // Use -ms-autohiding-scrollbar if you wish to display on hover.
    // -ms-overflow-style: -ms-autohiding-scrollbar;

    // There used to be a CSS rule that could hide scrollbars in Firefox, but it has since been deprecated.
    scrollbar-width: none;
}

div#root {
    touch-action: pan-y;
}

.HomePage {
    // @include after-mobile {
    //     margin-top: -25px;
    // }

    .CmsPage-Wrapper {
        @include container-fluid;

        .CmsPage-Content {
            margin-right: -15px;
            margin-left: -15px;

            > .CmsBlock-Wrapper {
                @include container-fluid;
            }

            > .CmsBlock-Wrapper_type_SliderHome {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .CmsBlock-Wrapper_type_SliderHome {
        padding-left: 0;
        padding-right: 0;
    }

    .category-links-home {
        position: relative;
        a {
            &::before {
                width: 100%;
                content: ' ';
                background-image: url('#{$mc__asset_public_path}/img/shadow-home-tiles.png');
                height: 100%;
                background-size: 100%;
                position: absolute;
                top: 0;
                background-repeat: no-repeat;
                z-index: 1;
            }
            display: block;
            color: #fff;
            line-height: 0;
            .subtitle-link {
                position: absolute;
                font-size: 2.4rem;
                line-height: 2.4rem;
                padding-top: 2rem;
                padding-left: 1.5rem;
                margin-top: 0px;
                font-family: $mc__font-family__montserrat;
                font-weight: normal;
                z-index: 1;
                color: white;
                @include after-mobile {
                    font-size: 5rem;
                    line-height: 4.3rem;
                    padding-top: 2.4rem;
                    padding-left: 2.7rem;
                }

                &--big {
                    @include after-mobile {
                        font-size: 6.6rem;
                        line-height: 6.6rem;
                        padding-left: 50px;
                        padding-top: 40px;
                    }
                }
            }
            img {
                width: 100%;
            }
        }
    }

    .mc-banner-home-grid {

        h2 {
            margin: 0 0 3px 0;
            text-align: left;
            font: $mc__font-weight__bold 20px $mc__font-family__yeseva;
            font-weight: normal;
            letter-spacing: 0px;
            color: $mc__color__dim-gray;
            @include after-mobile-mondoconv {
                font: $mc__font-weight__bold 40px $mc__font-family__yeseva;
            }
        }

        p {
            margin: 0;
            text-align: left;
            font: 16px $mc__font-family__montserrat;
            letter-spacing: 0;
            color: $mc__color__dim-gray;
            @include after-mobile-mondoconv  {
                font: 18px $mc__font-family__montserrat;
            }
        }

        .grid-mob {
            @include desktop {
                display: none;
            }
            padding: 12px 0;

            a:hover,
            a:active,
            a:visited {
                text-decoration: none;
            }

            .box {
                box-shadow: 0px 6px 20px #00000029;
                background-color: white;
                padding: 0;
                display: grid;
                grid-gap: 0;
                grid-template-columns: 2fr 5fr;
                margin-bottom: 10px;

                .Image {
                    padding-bottom: 100%;
                }

                img {
                    margin: 0;
                    width: 100%;
                    max-width: 100%;
                    height: auto;
                    border: 0;
                }

                .grid-box {
                    display: grid;
                    grid-gap: 0;
                    grid-template-columns: 7fr 1fr;
                    padding: 2px 8px;

                    .text {
                        display: flex;
                        align-content: flex-start;
                        justify-content: center;
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    .Image {
                        margin: auto;
                        padding-bottom: 50%;
                    }

                    img {
                        display: block;
                        margin: auto;
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }

        .grid-desk {
            @include mobile {
                display: none;
            }
            display: grid;
            grid-gap: 20px;
            grid-template-columns: 1fr 1fr 1fr;

            a:hover,
            a:active,
            a:visited {
                text-decoration: none;
            }

            .box {
                box-shadow: 0px 6px 20px #00000029;
                background-color: white;
                padding: 0;

                .grid-box {
                    display: grid;
                    grid-gap: 0;
                    grid-template-columns: 5fr 1fr;
                    padding: 6px 11px;
                    min-height: 94px;
                    @include after-mobile-mondoconv  {
                        padding: 26px 39px 34px 39px;
                    }

                    .Image {
                        margin: auto;
                    }

                    img {
                        display: block;
                        margin: auto;
                    }
                }

                .text {
                    min-height: 59px;
                    @include after-mobile-mondoconv  {
                        min-height: 95px;
                    }
                }
            }

            .Image {
                padding-bottom: 50%;
            }

            img {
                margin: 0;
                width: 100%;
                max-width: 100%;
                height: auto;
                border: 0;
            }
        }
    }

    .mc-banner-home {
        margin-top: .5rem;
        padding: 1rem 2rem;
        margin-bottom: 0;
        box-sizing: border-box;
        background-color: $default-secondary-dark-color;
        background-image: url('../../../public/img/consulente-bg-mobile.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        text-align: center;

        @include after-mobile {
            padding: 0 2rem;
            background-image: url('../../../public/img/consulente-bg-desktop.jpg');
        }

        h2 {
            text-align: center;
            font-weight: $mc__font-weight__bold;
            font-family: $mc__font-family__raleway;
            font-size: 2.4rem;
            line-height: normal;
            letter-spacing: 0;
            color: white;
            text-transform: uppercase;
            margin-bottom: 0;
            margin-top: .8rem;
            margin: .8rem 0 0;
            max-width: 90%;

            @include after-mobile {
                text-align: left;
                font-size: 3.2rem;
                max-width: none;
            }
          }

        h3 {
            text-align: center;
            letter-spacing: 0;
            font-family: $mc__font-family__raleway;
            font-weight: $mc__font-weight__regular;
            font-size: 1.4rem;
            line-height: normal;
            color: white;
            text-transform: uppercase;
            margin-top: 1.5rem;
            margin-bottom: 1rem;

            b {
                font-weight: $mc__font-weight__bold;
            }

            @include after-mobile {
                text-align: left;
                font-size: 2.8rem;
            }
        }

        a {
            text-transform: uppercase;
            text-align: center;
            font-weight: $mc__font-weight__bold;
            font-family: $mc__font-family__raleway;
            font-size: 1.9rem;
            letter-spacing: 0;
            color: $mc__color__venetian-red;
            border-radius: 4.1rem;
            background-color: white;
            padding: 2rem;
            display: inline-block;
            margin: .8rem auto;
            box-shadow: 0 .5rem .6rem $mc__color__night-rider;

            @include after-mobile {
                font-size: 2.3rem;
                margin: 2.5rem auto 2rem auto;
            }
        }

        .Image_ratio_square {
            padding-bottom: 198px;
        }
    }

    .bottom-pad {
        padding-top: 2rem;
        padding-left: 0;
        padding-right: 0;

        @include mobile {
            padding-top: 1rem;
        }

        @media (min-width: $mc__bp-sm) {
            &.col-sm-6:first-child {
                padding-right: 1rem;
            }

            &.col-sm-6:last-child {
                padding-left: 1rem;
            }
        }
    }

    .mc-catalgo-container {
        margin: 2rem 1rem;
        padding:20px 0px;
        display:flex;
        overflow-y: hidden;
        overflow-x: visible;
        @include hideScrollbar;
        @include mobile {
            overflow-x: scroll;
            padding:20px;
        }

        .bottom-pad {
            padding-left: .5rem;
            padding-right: .5rem;

            @media (min-width: $mc__bp-sm) {
                &.col-sm-6:first-child {
                    padding-right: 0;
                }

                &.col-sm-6:last-child {
                    padding-left: 0;
                }
            }
        }
    }
}
.SomethingWentWrong {
    &-SubHeading {
        display: none;
    }
    &-Heading {
        white-space: break-spaces;
    }
}
