@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.ScrollToTop {
  cursor: pointer;
  display: none;
  height: 56px;
  width: 56px;
  right: 30px;
  bottom: 90px;
  line-height: 5.5rem;
  position: fixed;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 2.2rem;
  border-radius: 50%;
  -webkit-transition: opacity .3s 0s, visibility 0s .3s;
  -moz-transition: opacity .3s 0s, visibility 0s .3s;
  transition: opacity .3s 0s, visibility 0s .3s, bottom 0.4s;
  z-index: 10;

  &_isVisible {
    display: inline-block;
  }

  @include mobile {
    height: 50px;
    width: 50px;
    line-height: 5rem;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}