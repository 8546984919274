@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/msp/mixin';

.Logo {
    width: 130px;
    height: 52px;

    @include after-mobile-mondoconv {
        top: -8px;
        width: 170px;
        height: 80px;
    }

    @media (min-width: 1200px) and (max-width: 1919px) {
        top: 1px;
        width: 116px;
        height: 56px;
    }

    @include mobile {
        width: 106px;
        height: 52px;
    }
}