
.RoudedInputButton {

    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #A8A8A8;
    opacity: 1;
    border-radius: 5px;
    height: 45px;
    width:100%;
    display: flex;
    .CartCoupon,
    .CartCap,
    .ProductCapBox {
        &-Input {
            flex: 1 1;
            margin: 0;
            margin-left: 15px;
            padding-left:0;
            padding-right:0;
            margin-right:20px;
            width: 100%;
            height: 41px;
            vertical-align: middle;
            border: none;
        }

        &-Button {
            border-radius: 5px;
            padding:0 7px;
            margin: -2px;
            padding:0 20px;
            height: 45px;
            opacity: 1;
            min-width: 150px;
        }

        &-Message {
            flex: 1 1;
            margin: 0;
            margin-left: 15px;
            padding: 9px 0;
            margin-right:20px;
            width: 100%;
            height: 41px;
            vertical-align: middle;
            border: none;
        }

    }

    &_Black {
        .CartCoupon,
        .CartCap {
            &-Button {
                background-color:$mc__color__night-rider;
            }
        }
    }

    &_Red {
        .CartCoupon,
        .CartCap {
            &-Button {
                background-color:$mc__color__venetian-red;
            }
        }
    }
}