@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.Notification {
    color: $mc__color__notification-success--color;
    background: $mc__color__notification-success--bg;
    border: 1px solid $mc__color__notification-success--border;

    &-Button {
        &::before,
        &::after {
            background-color: $mc__color__notification-success--color;
        }
    }

    &_type {
        &_error {
            color: $mc__color__notification-error--color;
            background: $mc__color__notification-error--bg;
            border: 1px solid $mc__color__notification-error--border;

            .Notification-Text {
                color: $mc__color__notification-error--color;
            }

            .Notification-Button {
                &::before,
                &::after {
                    background-color: $mc__color__notification-error--color;
                }
            }
        }
    
        &_success {
            color: $mc__color__notification-success--color;
            background: $mc__color__notification-success--bg;
            border: 1px solid $mc__color__notification-success--border;

            .Notification-Text {
                color: $mc__color__notification-success--color;
            }

            .Notification-Button {
                &::before,
                &::after {
                    background-color: $mc__color__notification-success--color;
                }
            }
        }
    
        &_info {
            color: $mc__color__notification-info--color;
            background: $mc__color__notification-info--bg;
            border: 1px solid $mc__color__notification-info--border;

            .Notification-Text {
                color: $mc__color__notification-info--color;
            }

            .Notification-Button {
                &::before,
                &::after {
                    background-color: $mc__color__notification-info--color;
                }
            }
        }
    }
}

