@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.SearchResultProduct {
    display: flex;
    flex-direction: row;
    font-size: 15px;
    padding: 5px 10px;
    background: white;
    margin-bottom: -10px;
    width: 100%;

    &-left {
        width: 100px;
    }

    &-right {
        flex-grow: 1;
    }

    &-image {
        img {
            width: 60px;
        }
    }

    &-name {
        font-weight: bold;
    }

    &-categories {
        color: #aaaaaa;
        font-size: 80%;
    }

    &-price {
        display: flex;
        flex-direction: row;
        margin-top: 5px;
        align-items: baseline;
        font-family: $mc__font-family__cabin;
        font-weight: $mc__font-weight__semibold;
    }

    &-sellPrice {
        color: $mc__color__night-rider;
        font-weight: 500;
    }

    &-originalPrice {
        text-decoration: line-through;
        margin-left: 10px;
        font-size: 90%;
        color: #bbbbbb;
    }
}
