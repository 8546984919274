@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
:root {
    --price-color: #{$mc__color__night-rider};
    --price-with-discount-color: #{$mc__color__night-rider};
    --price-discount-color: #{$mc__color__dim-gray};
}

.ProductPrice {
    font-family: $mc__font-family__cabin;
    font-weight: $mc__font-weight__medium;

    button.overlayButton {
        position: absolute;
        // top: 5px;
        right: 0;
        // &.isReverse {
            // top: -8px;
        // }
    }

    &_hasDiscount {
        white-space: nowrap;
    }

    .Overlay {
        top: -45px;
        left: -291px;
        width: 280px;
        height: 205px;
        background: #fff 0 0 no-repeat padding-box;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.14901960784313725);
        border: 1px solid #e7e7e7;
        border-radius: 5px;
        padding: 25px;
        margin-top: 20px;
        font-family: "Montserrat", sans-serif;
        white-space: break-spaces;
        &::before {
            content: "";
            position: absolute;
            top: 28px;
            height: 15px;
            right: -9px;
            width: 15px;
            transform: rotate(135deg);
            border-top: 1px solid #e7e7e7;
            border-left: 1px solid #e7e7e7;
            background: #fff 0 0 no-repeat padding-box;
        }
    }

    del {
        font-size: 2rem;
        opacity: 1;
        text-decoration: none;
        @media screen and (min-width: 768px) and (max-width: 863px) {
            font-size: 1.5rem;
        }

        &::after {
            content: "";
            border-bottom: 2px solid $mc__color__venetian-red;
            position: absolute;
            left: -10%;
            top: 50%;
            width: 120%;
            -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
        }
    }

    @include mobile {
        font-size: 5rem;
    }

    &-CurrentPrice {
        .currency {
            font-size: 2.8rem;
        }
    }

    &-HighPrice {
        margin-left: 12px;
        margin-right: 0;

        &_isReverse {
            margin-left: 0;
            margin-right: 12px;
        }
        .currency {
            font-size: 1.4rem;
        }
    }
    .top-miglior-prezzo {
        display: flex;
        flex-direction: column-reverse;
        @include mobile {
            display: flex;
            flex-direction: column-reverse;
            align-items: end;
            justify-content: end;
        }
        .miglior-prezzo-image {
            width: 100%;
            margin-bottom: 10px;
            max-width: 162px;
            @include mobile {
                width: 76px;
            }
        }
    }
    .bottom_primolivello {
        display: flex;
        flex-direction: column-reverse;
        align-items: end;
        .miglior-prezzo-image {
            width: 126px;
            margin-bottom: 10px;
            @include mobile {
                width: 76px;
            }
        }
    }
    .top_available_colors {
        display: flex;
        flex-direction: column-reverse;
        align-items: end;
        .miglior-prezzo-image {
            width: 92px;
        }
    }
    .left_menu {
        display: flex;
        flex-direction: row-reverse;
        gap: 10px;
        .miglior-prezzo-image {
            width: 76px;
            height: 19px;
        }
    }
    .fixedLeft {
        display: flex;
        gap: 25px;
        align-items: baseline;
        .miglior-prezzo-image {
            width: 126px;
        }
    }
}
.ProductListPage .ImageSticker-CustomDiscount {
    display: none;
}
.ProductListAvailableColors .ImageSticker-CustomDiscount {
    display: none;
}
.Fine_Serie .ProductListPage .ImageSticker-CustomDiscount {
    display: block;
}