@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.Footer {
    font-family: $mc__font-family__montserrat;

    @include mobile {
        // margin-bottom : 70px;
    }

    &-Content {
        background-color: #152c69;
    }

    &-Columns {
        color: $white;
        padding: 30px 5px 40px;
        border-bottom: 2px solid #FFFFFF;
        justify-content: center;

        .block-wrapper {
            @include mobile {
                flex-direction: column;
                align-items: center;
                text-align: left;
            }
        }

        .block-title{
            font-weight: $mc__font-weight__bold;
            letter-spacing: 3px;
            margin: 0;
            padding: 20px 0;
            font-size: 12px;

            a {
                color: $white;
                text-decoration: none;


                &:hover,
                &:active {
                    color: $default-secondary-light-color;

                }
            }

        }
        .block-content {
            @include mobile {
                padding-bottom: 20px;
                border-bottom: 1px solid #e5e5e5;
            }

            ul {
                list-style: none none;
                li {

                    margin: 0;
                    padding: 0;
                    list-style: none none;
                    text-decoration: none;

                    &::before {
                        content: '';
                    }

                    a {
                        color: $white;
                        font-family: $mc__font-family__montserrat;
                        font-weight: $mc__font-weight__regular;
                        line-height: 1.8rem;
                        font-size: 1.2rem;
                        text-decoration: none;

                        &:hover,
                        &:active {
                            color: $default-secondary-light-color;

                        }
                    }
                }
            }
            &.social-icons {
                @include mobile {
                    padding-bottom: 20px;
                    border-bottom: none;
                    &::after {
                        content: "";
                    }
                }
                ul {
                    li {
                        display: inline;
                        a {
                            font-size: 22px;
                            display: block;
                            float: left;
                            width: 38px;
                            height: 38px;
                            line-height: 38px;
                            border: 1px solid $mc__color__grey60;
                            margin-right: 5px;
                            border-radius: 50%;
                            text-align: center;
                            margin-bottom: 10px;

                            &:hover,
                            &:active {
                                background-color: $white;
                                color: var(--secondary-base-color);
                            }
                        }
                    }
                }
            }

            &.mc-tracking {
                margin: 2em 0;
                border: 1px solid $mc__color__grey66;
                border-radius: 3px;
                cursor: pointer;
                padding: 5px 0;
                color: $white;

                .wrapper {
                    display: flex;
                    flex-direction: row;
                    margin: 0 -0.5em;

                    > * {
                        padding: 0 .5em;
                    }

                    .mc-tracking-logo {
                        font-size: 2.2rem;
                        min-width: 30px;
                        text-align: center;

                        .icon-tracking {
                            font-size: 2.2rem;
                            min-width: 30px;
                            text-align: center;
                        }
                    }

                    .mc-tracking-text {
                        font-size: 1.1rem;
                        flex-grow: 1;
                    }
                }

                a:hover,
                a:focus,
                a:visited {
                    text-decoration: none;
                }
            }
        }
    }

    &-Logo {
        position: relative;
        padding-bottom: 0;
        width: 100px;
        height: 40px;
    }

    &-AltroContentWrapper {
        background-color: #152c69;
        margin-top: 0;

        .Footer-AltroContent {
            padding-right: 2rem;
            padding-left: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        p {
            font-size: 1.2rem;
            color: $white;
            text-align: center;
            letter-spacing: 2.4px;
            padding: 15px;

            a {
                color: $white;
                text-decoration: none;

                &:hover,
                &:active {
                    color: $default-secondary-light-color;
                }
            }
        }

    }

    &-CopyrightContentWrapper {
        background-color: var(--primary-light-color);
        margin-top: -4px;

        .Footer-CopyrightContent {
            padding-right: 2rem;
            padding-left: 2rem;
            display: flex;
            align-items: center;
            justify-content: flex-start
        }

        .Footer-Copyright {
            font-size: 1.1rem;
            color: $white;
            text-align: left;
            padding-left: 15px;

        }

    }

    &-LegalNotes {
        cursor: pointer;
    }
}
