@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/msp/mixin';

.SearchField {
    flex:1 1;
    @include before-mobile-mondoconv {
        flex:1 1 100%;
        order: 5;
        margin-right: 7px;
        margin-left: 7px;
    }
    margin-left: 28px;
    margin-right: 5px;

    @include mobile {
        margin-bottom: 15px;
    }

    &-Wrapper {
        margin-right: 0;
    }
    &-SearchTitle {
        cursor: pointer;
        color: $mc__color__night-rider;
        font-size: 1.1rem;
        min-width: 6.5rem;
        text-align: center;
    }

    &-SearchIcon {
        @include after-mobile {
            width: 6.5rem;
        }

        &::before {
            box-shadow: none;
            border: 0;
            font: normal normal normal 14px/1 FontAwesome;
            content: "\f002";
            color: $mc__color__night-rider;
            left: 50%;
            transform: translateX(-50%);
            top: 0;
        }
    }

    &-Placeholder::before {
        border: 0;
        font: normal normal normal 14px/1 FontAwesome;
        content: "\f002";
        top: 0;
        transform: translateY(-50%);
    }

    &-CloseIcon {
        &::before {
            box-shadow: none;
            border: 0;
            font: normal normal normal 14px/1 FontAwesome;
            content: "\f00d";
            color: $mc__color__night-rider;
            left: 50%;
            top: 0;
            background-color: transparent;
            transform: rotate(0);
        }
        &::after {
            display: none;
        }
    }

    [class^=ais-] {
        box-sizing: border-box;
    }

    .ais {

        &-Results {
            position: absolute;
            left: -400px;
            top: 50px;
            background: white;
            border: 1px solid #666666;
            padding: 10px;
        }

        &-Hits-list {
            list-style-type: none;
        }

        &-Hits-item {
            text-align: left;
            padding-left: 0;
        }
    }
}
