@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.bannerContainer {
    width: 100%;
    height: 70px;
    background-color: #e7e7e7;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.bannerText {
    height: 19px;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 16px;
    color: #333333;
}
.openTdcPopup {
    background-color: transparent;
    border: none;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    &:focus {
        border: none;
        outline: 0 !important;
    }
}
.fa-exclamation-triangle {
    margin-right: 11.4px;
    font-size: 18px;
}
.tracciato{
    height: 19px;
    font-size: 16px;
    transform: matrix(0, 1, -1, 0, 0, 0);
    margin-left: 10.16px;
    margin-top: 1px;
    font-weight: 100;
    cursor: pointer;
}