@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import "../../style/msp/mixin";

$sidePopupMaxWidth: 530px;
$sidePopupMinWidth: 300px;

.Popup {
    z-index: 99999999;

    &-Wrapper {
        font-family: $mc__font-family__montserrat;
        border: 1px solid $mc__color__grey66;
        //min-width: var(--popup-min-width);
        //max-width: calc(var(--content-wrapper-width) * .8);
        background-color: var(--popup-content-background);
        filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.3));

        @media screen and (max-width: 811px) and (min-width: 501px) {
            width: 75vw;
            height: auto;
            border-radius: 5px;
        }

        @media screen and (max-width: 500px) {
            width: 95vw;
            height: auto;
            border-radius: 5px;
        }
    }

    @include mobile {
        align-items: center;
    }

    &_isVisible {
        pointer-events: all;
        opacity: 1;
        width: 100vw;
        height: 100vh;
        height: -webkit-fill-available;
    }

    &-Heading {
        font-family: $mc__font-family__montserrat;
    }

    &-Header {
        @include mobile {
            display: block;
        }
    }

    & &-CloseBtn {
        @include mobile {
            top: -3.9rem;
            right: -3.9rem;
        }

        top: -3.5rem;
        right: -3rem;

        &:before,
        &::after {
            width: 3px;
        }
    }

    &-Body {
        max-height: 80vh;
        max-width: 800px;
        overflow: hidden;
        overflow-y: auto;
        padding: 10px;
        padding-top: 30px;
        margin: 20px;

        &::-webkit-scrollbar {
            right: -20px;
            width: 0.5rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid #708090;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        @include custom-popup-styles;

        h2 {
            font-size: 3rem;
            font-weight: 600;
            margin-bottom: 35px;
        }
    }

    &_isOverlayPopup {
        @include before-mobile-mondoconv {
            top: 0;
        }

        @include mobile {
            z-index: 16000002;
        }

        .Popup {
            &-Body {
                max-width: 500px;
                max-height: 90vh;

                @media screen and (max-height: 760px) {
                    max-width: 400px;
                    @media (min-resolution: 1.10dppx) {
                        /* Caso zoom > 100% (125% in questo esempio) */
                        max-width: 700px;
                      }
                }

                @include before-mobile-mondoconv {
                    .CmsBlock-Wrapper {
                        max-height: calc(80vh - 50px);
                        overflow-y: auto;
                        overflow-x: hidden;
                    }

                    @media screen and (max-width: 767px) {
                        .CmsBlock-Wrapper {
                            max-height: 100% !important;
                        }
                    }
                }
            }
        }
    }

    //& &-CloseBtn {
    //    @include custom-close-button;
    //}

    &_isSidePopup {
        justify-content: flex-end;
        height: calc(100vh - var(--header-total-height));
        background: none;

        position: fixed;
        top: var(--header-total-height);
        z-index: 99;

        .Popup {
            &-CloseBtn {
                position: fixed;
                left: 10px;
                top: 15px;

                &::before {
                    width: 32px;
                    height: 32px;
                    background-size: 24px 24px;
                    background-repeat: no-repeat;
                    background-color: transparent;
                    top: 4px;
                    left: 4px;
                    transform-origin: initial;
                    transform: initial;
                }

                &::after {
                    background-color: transparent;
                }
            }

            @include mobile {
                &-Header {
                    display: block;
                }

                &-CloseBtn {
                    position: fixed;
                    left: 5px;
                    top: var(--header-total-height);

                    &::before {
                        width: 32px;
                        height: 32px;
                        background-size: 32px 32px;
                        background-image: url("./assets/close-black.svg");
                    }
                }

                &-Body {
                    @include desktop {
                        padding-left: 30px;
                        padding-top: 10px;
                    }

                    @include mobile {
                        margin: 5px 0 0 40px;
                    }
                }
            }

            @include desktop {
                &-CloseBtn {
                    margin: 0;
                    width: 32px;
                    height: 32px;
                    border-radius: 16px;
                    background: #333333;
                    position: fixed;
                    right: inherit;
                    top: 20px;
                    left: -16px;
                    z-index: 1000;

                    &::before {
                        background-image: url("./assets/close-white.svg");
                    }
                }
            }

            &-Wrapper {
                @include mobile {
                    filter: none;
                    width: 100vw;
                    //height: calc(100vh - var(--header-tota-height));
                    overflow-y: auto;
                }

                border: 0;
                border-radius: 0;
                height: 100%;
                filter: drop-shadow(-5px 0px 4px rgba(0, 0, 0, 0.3));
                display: flex;
            }

            &-Body {
                &.newAddToCart {
                    max-width: 700px;
                    width: 100%;
                    padding: 0;
                    margin: 0;

                    @include mobile {
                        max-width: 100%;
                    }

                    >div {
                        max-width: 700px;

                        @include mobile {
                            max-width: 100%;
                        }
                    }

                    h3.Popup-Heading {
                        display: none;
                    }

                    button.Popup-CloseBtn {
                        position: fixed;
                        right: 35px;
                        top: 40px;
                        left: unset;
                        width: 40px;
                        height: 40px;
                        background-color: #1d2e66;
                        border-radius: 50%;

                        &::before {
                            background-size: 35px;
                            width: 40px;
                            height: 40px;
                            transform: translate(-1px, -1px);
                        }

                        @include mobile {
                            top: 110px;
                            right: 10px;

                            &::before {
                                background-image: url("./assets/close-white.svg");
                            }
                        }
                    }
                }

                >div {
                    max-width: 488px;

                    @media screen and (max-width: 1440px) {
                        width: 100%;
                        max-width: 100%;
                    }

                    @include mobile {
                        width: 100%;
                        max-width: 100%;
                    }

                    @include desktop {
                        min-width: #{$sidePopupMinWidth};
                        max-width: #{$sidePopupMaxWidth};
                    }
                }

                @include mobile {
                    width: 100vw;
                    max-height: unset;
                    padding-bottom: 120px;
                }

                @include desktop {
                    max-height: 100%;
                    width: calc((100vw - 1400px) / 2 + #{$sidePopupMaxWidth});
                    max-width: unset;
                    min-width: 400px;
                }
            }
        }
    }

    &_isFullPopup {
        @media screen and (max-width: 767px) {
            height: 100vh !important;
            top: 0 !important;
        }

        height: calc(100vh - var(--header-total-height));
        top: var(--header-total-height);

        @include mobile {
            height: calc(100vh - var(--header-total-height));
        }

        .Popup {
            &-Wrapper {
                width: 100%;
                height: 100%;
                filter: drop-shadow(-5px 0 4px rgba(0, 0, 0, 0.3));

                @include mobile {
                    width: 100vw;
                    border-radius: 0;
                }
            }

            &-CloseBtn {
                top: -2rem;
                right: -4rem;
            }

            &-Body {
                max-width: unset;
                max-height: 100vh;
                height: 100%;
                margin: 0;
                padding: 20px;

                @include desktop {
                    padding: 60px 80px;

                    .Popup-CloseBtn::before,
                    .Popup-CloseBtn::after {
                        width: 7px;
                        height: 26px;
                    }

                    .box {
                        display: flex;

                        .Image {
                            img {
                                width: 100% !important;
                            }
                        }

                        .text {
                            margin-left: 20px;
                        }
                    }
                }

                &.Closet {
                    @media screen and (min-width: 811px) and (max-width: 1440px) {
                        padding: 60px 30px;
                    }

                    button.Popup-CloseBtn {
                        position: fixed;
                        text-align: right;
                        right: 3%;
                        top: 3%;

                        @include mobile {
                            right: 15px;
                            top: 7px;
                            background-color: #333;
                            border-radius: 50%;

                            &::after {
                                transform: rotate(45deg) translate(-12px, 18px);
                                background-color: #fff;
                            }

                            &:before {
                                transform: rotate(-45deg) translate(-18px, -12px);
                                background-color: #fff;
                            }
                        }
                    }

                    @include mobile {
                        .PopupClosetCard {
                            padding-bottom: 40px;
                        }
                    }
                }
            }
        }
    }
}

.Popup.Popup_isVisible.Popup_isSidePopup.newAddToCart {
    height: 100vh;
    top: 0;
    z-index: 99999;

    .Popup-LeftSide {
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.3;

        @include mobile {
            height: 100px;
            min-height: 100px;
            min-width: 100%;
            position: absolute;
            top: -100px;
            left: 0;
        }
    }

    .Popup-Wrapper {
        flex: 0 0 auto;
    }

    @include mobile {
        height: calc(100vh - 100px);
        top: 100px;
        z-index: 9999;
    }
}

.Popup-Body.limitedItem {
    font-size: 14px;
    text-align: center;

    h3.Popup-Heading {
        font-size: 21px;
        text-align: center;
    }
}

.Popup.Popup_isOverlayPopup.Availability-ADO {
    .Popup-Wrapper {
        max-width: 739px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 10px 20px #00000040;
        border-radius: 30px;

        .Popup-Body {
            p.title {
                text-align: center;
                font: normal normal 600 24px/29px Montserrat;
                color: #333333;
                text-transform: uppercase;
                margin-bottom: 10px;
            }

            p.desc {
                text-align: center;
                font: normal normal normal 16px/19px Montserrat;
                color: #333333;
                margin-bottom: 20px;
            }

            button.back,
            a.back {
                max-width: 375px;
                height: 48px;
                background: #152c69 0% 0% no-repeat padding-box;
                border-radius: 24px;
                text-align: center;
                font: normal normal bold 14px/18px Montserrat;
                color: #ffffff;
                text-transform: uppercase;
                width: 100%;
                margin: 0 auto 15px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            button.edit {
                max-width: 375px;
                text-align: center;
                text-decoration: underline;
                font: normal normal bold 12px/15px Montserrat;
                color: #152c69;
                text-transform: uppercase;
                width: 100%;
                margin: 0 auto 15px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.Popup.CartPage-ADO-Warehouse {
    .Popup-Wrapper {
        border-radius: 30px;
    }

    .Popup-Body.CartPage-ADO-Warehouse {
        max-width: 730px;
        padding: 35px;

        button.Popup-CloseBtn {
            display: none;
        }
    }

    .CartPage-ADO.Warehouse {
        .title {
            text-align: center;
            font: normal normal 600 24px/29px Montserrat;
        }

        .description {
            font: normal normal normal 16px/19px Montserrat;
            margin-bottom: 20px;

            @include mobile {
                text-align: center;
            }
        }

        button {
            width: 375px;
            height: 48px;
            background: #152c69 0% 0% no-repeat padding-box;
            border-radius: 24px;
            text-align: center;
            font: normal normal bold 14px/18px Montserrat;
            color: #ffffff;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;

            @include mobile {
                width: 100%;
            }
        }
    }
}

.Popup.Popup_isVisible.Popup_isSidePopup {
    .Popup-Body.CartPage-ADO {
        max-width: 580px;
        padding: 35px;

        @include mobile {
            padding: 20px;

            button.Popup-CloseBtn {
                left: unset !important;
                right: 5px !important;
                top: 15px !important;
            }
        }
    }

    .CartPage-ADO {
        height: 100%;
        width: 100%;
        margin: auto;
        max-width: 510px;

        .title {
            font: normal normal 600 24px/32px Montserrat;
            color: #333333;
            margin-bottom: 20px;

            @include mobile {
                font-size: 20px;
                line-height: 26px;
            }
        }

        .subtitle {
            font: normal normal normal 16px/19px Montserrat;
            color: #333333;
            margin-bottom: 35px;

            @include mobile {
                font-size: 15px;
            }
        }

        .items {
            max-height: calc(100% - 320px);

            @include mobile {
                max-height: calc(100% - 383px);
            }

            overflow-y: auto;

            .card {
                display: grid;
                grid-template-columns: auto 1fr;
                gap: 25px;
                justify-content: flex-start;
                align-items: flex-start;
                border-bottom: 1px solid #333333;
                padding-bottom: 37px;
                margin-bottom: 37px;
                position: relative;

                >div {
                    height: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                }

                @media screen and (min-width: 769px) and (max-width: 1680px) {
                    padding-bottom: 25px;
                    margin-bottom: 25px;
                }

                @include mobile {
                    gap: 11px;
                    padding-bottom: 33px;
                    margin-bottom: 21px;
                }

                &:last-child {
                    border-bottom: none;
                    margin-bottom: 0;
                }

                h1.linkedName {
                    font: normal normal normal 30px/34px Yeseva One;
                    margin-bottom: 1px;

                    @media screen and (min-width: 769px) and (max-width: 1680px) {
                        font: normal normal normal 21px/24px Yeseva One;
                    }

                    @include mobile {
                        font: normal normal normal 18px/20px Yeseva One;
                    }
                }

                img {
                    width: 200px;

                    @media screen and (min-width: 769px) and (max-width: 1680px) {
                        width: 169px;
                    }

                    @include mobile {
                        width: 110px;
                    }
                }

                p.linkedDesc,
                p.linkedMeasures {
                    font: normal normal normal 14px/18px Montserrat;
                    margin-bottom: 1px;

                    @media screen and (min-width: 769px) and (max-width: 1680px) {
                        font: normal normal normal 12px/15px Montserrat;
                    }

                    @include mobile {
                        font: normal normal normal 12px/15px Montserrat;
                    }
                }

                p.linkedMeasures {
                    margin-bottom: 10px;
                }

                .notAvailable {
                    text-align: left;
                    font: normal normal bold 14px/18px Montserrat;
                    color: #333333;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    flex-direction: row;
                    margin: 0;

                    @include mobile {
                        font: normal normal bold 12px/16px Montserrat;
                    }

                    &::before {
                        content: "";
                        display: block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: #db230b;
                    }
                }
            }
        }

        .bottom-container {
            position: absolute;
            bottom: 0;

            @include mobile {
                position: relative;
                bottom: 0;
            }

            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            button {
                border-radius: 24px;
                width: 100%;
                height: 48px;
                font: normal normal normal 14px/36px Montserrat;
                letter-spacing: 0.42px;
                margin-bottom: 0;
                display: flex;
                justify-content: center;
                align-items: center;

                @include mobile {
                    font-size: 12px;
                }
            }

            button.one {
                color: #333333;
                background: #ffffff 0% 0% no-repeat padding-box;
                border: 1px solid #a8a8a8;
            }

            button.two {
                font-weight: bold;
                background: #152c69 0% 0% no-repeat padding-box;
                color: #fff;

                &:disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
            }
        }
    }
}

@media screen and (min-width: 810px) {
    .Popup-Body.Collection .Popup-CloseBtn {
        position: fixed !important;
        top: 3rem !important;
        right: 2rem !important;
    }
}

.Popup-Body.Collection .ProductPrice .top-miglior-prezzo {
    align-items: flex-end;
}

.Popup.Popup_isVisible.Popup_isSidePopup.CartPage-ADO {
    height: 100vh !important;
    top: 0 !important;
    z-index: 100 !important;
}

.Popup {
    &_isFullPopup {
        .Closet {
            .Popup-Header {
                @include mobile {
                    position: fixed;
                    top: 0;
                    right: 0px;
                    height: 40px;
                    width: 100%;
                    background-color: #fff;
                    z-index: 99;

                    button.Popup-CloseBtn {
                        right: 10px;
                        top: 0;
                        background-color: transparent;
                        border-radius: 50%;

                        &::after,
                        &::before {
                            background-color: #333;
                        }
                    }
                }
            }

            .PopupClosetCard {
                @include mobile {
                    padding-top: 40px;
                }
            }
        }
    }
}

.oldAddToCart {
    .Popup-CloseBtn {
        display: none;
    }

    .Popup-Heading {
        display: none;
    }

    .Popup-Wrapper {
        border-radius: 32px;

        .Popup-Body {
            padding-top: 0px;
            margin: 40px;

            @include mobile {
                margin: 30px;
            }

            .AddToCartPopup {
                img {
                    content: url('./assets/added.png');
                    width: 60px;
                    margin: auto;
                    display: flex;
                    margin-bottom: 15px;

                    @include mobile {
                        width: 53px;
                    }
                }

                &-Title {
                    font-family: 'Montserrat';
                    font-size: 25px;
                    font-weight: 700;
                    line-height: 30px;
                    text-align: center;
                    margin-bottom: 10px;
                    color: #1D2E66;

                    @include mobile {
                        font-size: 18px;
                        margin-bottom: 5px;
                    }
                }

                &-Description {
                    font-family: 'Montserrat';
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19px;
                    text-align: center;
                    max-width: 330px;
                    margin: auto;

                    @include mobile {
                        max-width: 240px;
                        font-size: 12px;
                        line-height: 14px;

                    }
                }

                &-Actions {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-top: 20px;
                    gap: 30px;

                    @include mobile {
                        flex-direction: column;
                        gap: 10px;
                    }

                    .Button {
                        min-width: 221px;
                        height: 48px;
                        font-family: Montserrat;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 17px;
                        text-align: center;
                        margin-bottom: 0;
                        box-shadow: none !important;

                        @include mobile {
                            height: 38px;
                            font-size: 12px;
                            line-height: 14px;
                            padding: 10px;
                        }

                        &.first {
                            background-color: #fff;
                            border-color: #152C69;
                            border-width: 2px;
                            color: #152C69;
                        }
                    }
                }
            }
        }
    }
}