.ProductPage {
    @include desktop {
        padding-top: 0;

        &:before {
            @include product-gradient();
        }

        .Slider-Crumbs {
            display: none;
        }
    }

    .ContentWrapper.ProductPage-Wrapper {
        @include desktop {
            grid-column-gap: 2.6rem;
        }
    }

    .Slider {

    }

    .Slider-Crumbs {
        grid-gap: 20px;
        bottom: 0;
    }
    .Slider-Crumb {
        border-radius: 0;
        background-color: $mc__color__whisper;
        border-color: $mc__color__whisper;
        width: 20px;
        height: 4px;

        &_isActive {
            border-color: black;
            background-color: black;
        }
    }

    .CmsBlock-Wrapper_type_HotlineNumber {
        order: 2;
    }

    &-PuoiAcquistareContainer{
        width: 100%;
        max-width: 100%;
        display:block;
        overflow:hidden;

        @include mobile {
            padding: 0 20px;
        }
    }

    &-PuoiAcquistareCarousel{
        display:block;
        width: 100%;
        height: 300px;

        @include mobile {
            overflow-x: scroll;
            white-space: nowrap;
        }

        @include desktop {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &-PuoiAcquistareItem {
        display: inline-block;
        position:relative;
        width: 180px;
        margin-right: 25px;

        &:last-child {
          margin-right: 0;
        }
    }

    &-PuoiAcquistareTitle {
        font: normal normal $mc__font-weight__semibold 3rem/1.8rem $mc__font-family__montserrat;
        margin-bottom: 30px;

        @include mobile {
            padding: 0 1.5rem;
            font: normal normal $mc__font-weight__semibold 2.2rem/1.4rem $mc__font-family__montserrat;
        }
    }

    &-PuoiAcquistareSubtitle {
        font: normal normal bold 16px/19px $mc__font-family__montserrat;
    }

    .DisponibilitaNotice {
        display: block;
        @include desktop {
            display: none;
        }
    }
}
