@import './mixin';

main {
    @include mobile {
        margin-top: 0;
    }
}

h1 {
    + h2 {
        margin-top: $mc__h2__margin-top;

        @include mobile {
            margin-top: $mc__h2__margin-top;
        }
    }

    + h3 {
        margin-top: $mc__h3__margin-top;

        @include mobile {
            margin-top: $mc__h3__margin-top;
        }
    }

    + h4 {
        margin-top: $mc__h4__margin-top;

        @include mobile {
            margin-top: $mc__h4__margin-top;
        }
    }
}

h2 {
    + h3 {
        top: 0;
        margin-top: $mc__h3__margin-top;
        margin-bottom: $mc__h3__margin-bottom;

        @include mobile {
            top: 0;
            margin-top: $mc__h3__margin-top;
            margin-bottom: $mc__h3__margin-bottom;
        }
    }

    + h4 {
        top: 0;
        margin-top: $mc__h4__margin-top;
        margin-bottom: $mc__h4__margin-bottom;

        @include mobile {
            top: 0;
            margin-top: $mc__h4__margin-top;
            margin-bottom: $mc__h4__margin-bottom;
        }
    }
}

h3 {
    + h4 {
        top: 0;
        margin-top: $mc__h4__margin-top;
        margin-bottom: $mc__h4__margin-bottom;

        @include mobile {
            top: 0;
            margin-top: $mc__h4__margin-top;
            margin-bottom: $mc__h4__margin-bottom;
        }
    }
}

p {
    font-family: $mc__font-family__montserrat;
}
