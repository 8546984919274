.ProductCard-Content_is2prod {
    .ProductPrice {
        font-size: 4rem;

        .currency {
            font-size: 2.2rem;
        }

        del {
            font-size: 1.8ren;
        }

        &-HighPrice {
            .currency {
                font-size: 1.2rem;
            }
        }
    }
}

.ProductCard-Content_is3prod {
    .ProductPrice {
        font-size: 3.3 rem;

        .currency {
            font-size: 2rem;
        }

        del {
            font-size: 1.8ren;
        }

        &-HighPrice {
            .currency {
                font-size: 1.2rem;
            }
        }
    }
}