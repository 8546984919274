@import './mixin';

.BlackFridayCategory {
    color: $white;
    .CategoryPage {
        
        &-OrderByOverlay > * {
            background-color: transparent;
        }
        
    }
    .Overlay {
        background-color: transparent;
    }
}

.ProductCard {
    &_promo {
        &_blackfriday {
            padding-bottom: 50px;

            color: $white;
            .ProductCardDetails {
                &-Name {
                    color: $white;
                }
    
                &-ColorsTextLink {
                    a {
                        color: $white;
                    }
                }
    
                &-ColorTrigger {
                    &_isActive {
                        border-color: $white;
                    }
                }
    
                &-PriceWrapper {
                    .ProductPrice {
                        color: $white;
                        &_hasDiscount {
                            color: $white;
                        }
                        &-HighPrice {
                            color: $white;
                        }
                    }
    
                }
    
                &-ActionWrapper,
                &-PromoActionWrapper {
                    .AddToCart,
                    .ProductCard-CTA {
                        color: $mc__color__night-rider;
                        background-color: $white;
                        @include before-mobile-mondoconv {
                            flex: 1 1 100%;
                            margin-top: 30px;
                        }
                    }
                }

                &-PromoAction {

                    @include before-mobile-mondoconv{
                        flex:1 1 100%;
                    }

                    &_isClosed {
                        flex:1 1 100%;
                    }
                } 
            }

        }
    }

}