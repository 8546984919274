@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/msp/mixin';

.CustomSearchBar {
    &-Form {
        position: relative;
    }

    &-Input {
        background: $white;
        font-family: $mc__font-family__montserrat;
        font-weight: $mc__font-weight__lighter;
        color: $mc__color__night-rider;
        border: 2px solid $mc__color__grey66;
        width: 100%;
        border-radius: 23px;
        opacity: 1;
        padding: 1.2rem 2.7rem;
        appearance: none;
        height: 45px;
        font-size: 1.6rem;

        &::after {
            content: none;
        }

        &::before {
            content: none;
        }

        &:focus {
            background: $mc__color__white-smoke;
            border: 2px solid $mc__color__grey66;

        }

        @include before-mobile-mondoconv {
            height: 35px;
        }

    }

    &-Submit,
    &-Reset {
        padding: 0;
        background: 0 0;
        border: 0;
        cursor: pointer;
        user-select: none;
        appearance: none;
        position: absolute;
        z-index: 1;
        width: 25px;
        height: 25px;
        top: 50%;
        right: 1.5rem;
        transform: translateY(-50%);

        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-Icon {
        height: 23px;
        width: 23px;


        @include mobile {
            height: 20px;
            width: 20px;

            path {
                fill: #4f4e4e;
            }
        }
    }

    &-Reset {

        &::before,
        &::after {
            content: '';
            position: absolute;
            width: 1px;
            height: 20px;
            transform-origin: 50% 50%;
            top: 7px;
            transform: rotate(-45deg);
            background-color: var(--header-color);
        }

        &::after {
            transform: rotate(45deg);
        }

        width: 35px;
        height: 35px;

        right: 4rem;
        display: none;

        &_isVisible {
            display: block;
        }
    }
}