@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
$circleSize: 18px;
$duration: 1s;
$delayRatio: 10;
$minScale: .2;
$maxScale: 1.5;

@keyframes bubble {
    0%   { transform: scale($minScale, $minScale) }
    50%  { transform: scale($maxScale, $maxScale) }
    100%  { transform: scale($minScale, $minScale) }
}

.McLoader {
    &-Main {
        width: 100vw;
        height: 100vh;
        background: rgba(255,255,255,.5);
        position: fixed;
        top: 0;
        padding-top: 40vh;
        left: 0;
        z-index: 1000;

        svg {
            display: block;
            margin: auto;
            width: 200px;
            height: 100px;
        }
    }

    &-Flag {
        margin: -10px auto 0 auto;
        display: flex;
        width: 100px;
        height: 50px;
        justify-content: space-between;

        > div {
            width: $circleSize;
            height: $circleSize;
            border-radius: #{$circleSize / 2};
            border: 1px solid #999;
            animation-name: bubble;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            animation-duration: #{$duration};
        }
    }

    &-GreenCircle {
        background-color: #467532;
    }

    &-WhiteCircle {
        background-color: white;
        animation-delay: #{$duration / $delayRatio};
    }

    &-RedCircle {
        background-color: #d21f1f;
        animation-delay: #{2 * $duration / $delayRatio};
    }
}
