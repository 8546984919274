@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
$popup_width: 300px;

.PromoPopup {
    font-family: $mc__font-family__montserrat;
    font-size: 14px;

    &-SidePopup {
        position: fixed;
        bottom: 20px;
        left: 20px;
        width: $popup_width;
        height: $popup_width;
        z-index: 1000;

        @include mobile {
            bottom: 20px;
            left: calc(50vw - #{$popup_width / 2});
            width: $popup_width;
            height: $popup_width;
        }
    }

    &-Top {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        width: 100vw;

        > div {
            height: $mc__promo_popup_height;
            @include mobile {
                height: $mc__promo_popup_height_mobile;
            }

            > div {
                text-align: center;
                padding: 0 1rem;
                flex: 1 1;
            }

            display: flex;
            align-items: center;
            flex-direction: row;
        }

        @include mobile {
            font-size: 90%;
        }

        .popupRed {
            background: red;
            color: white;
        }

        .popupBlue {
            background: darkblue;
            color: white;
        }

        .popupGreen {
            background: darkgreen;
            color: black;
        }

    }

    &-CloseBtn {
        position: absolute;
        left: 5px;
        top: 5px;
        z-index: 1001;

        svg {
            width: 24px;
            height: 24px;

            > * {
                opacity: .5;
            }
        }
    }
}

// html.PromoPopUpVisible{
//     padding-top:35px;
// }
