@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.NewsletterCustomPopup {
    font-size: 1.5em;

    h4 {
        font-size: 1.5em;
    }

    &-body {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &-icon {
        margin-right: 10px;
        &::before {
            font-size: 32px;
            color: darkgreen;
        }
    }

    &-text {
        flex-grow: 1;
    }
}
