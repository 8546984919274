@import './mixin';


.mc-menu {
    &-product{
        padding:20px;
        font-size: 0;

        &__image {
            padding-bottom:62.5% !important;
        }
        &__bottom {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color:transparent;
            height: 2.5rem;
            line-height: 3.2rem;
            text-align: right;
            font-size: 1.8rem;
            font-weight: $mc__font-weight__medium;
            font-family: $mc__font-family__roboto;
            text-transform: uppercase;
            padding: 0;
            letter-spacing: 0;
            color:$mc__color__night-rider;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: end;
            transform: translateY(100%);

            .ProductPrice {
                text-wrap: none;
                white-space: nowrap;

                &-CurrentPrice {
                    font-size: 2.1rem;
                }

                &-HighPrice {
                    font-size: 1.5rem;
                }

                .currency {
                    font-size: 70%;
                }
            }
        }

        &__name {
            font-size: 1.5rem;
            font-weight: $mc__font-weight__bold;
            line-height: 100%;
            @include before-viewport-mondoconv {
                font-size: 1.1rem;
            }
        }
    }

    &-category {
        padding: 12px 12px 38px 12px;
        font-size: 0;
        &:hover {
            border: 2px solid #152C69;
            border-radius: 13px;
            padding: 10px 10px 36px 10px;
        }
    }

}

@mixin menu-text-style  {
    height: 100%;
    font-size: 1.5rem;
    font-family: $mc__font-family__roboto;
    font-weight: $mc__font-weight__medium;
    letter-spacing: 0px;
    padding:3px 0;
    border-bottom:none;
    line-height: 2.6rem;

    @include desktop {
        font-size: 1.3rem;
    }

    &:hover,
    &_isActive{
        text-decoration: none;
        @include desktop {
            // border-bottom: 3px solid $mc__color__venetian-red;
            // padding-bottom: 0;
        }
    }

    &_isMainMenu {
        text-transform: uppercase;
    }

    &_isOfferte {
        background-color: $mc__color__venetian-red;
        border:3px solid $mc__color__venetian-red;
        border-radius: 24px;
        &::after {
            content: '%';
        }

        color:$white;
        padding:5px 15px;

        &:hover {
            text-decoration: none;
            @include desktop {
                border: 3px solid $mc__color__venetian-red;
            }
            background-color: $white;
            color: $mc__color__venetian-red;
        }

    }

}

@mixin menu-link-style {
    &:hover,
    &:focus {
        text-decoration: none;
    }
    cursor: pointer
}

@mixin menu-category-style {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    opacity: 1;
}

@mixin menu-item-style {
    padding:4px 20px;
    margin:0;
    text-transform: uppercase;
    &::before{
        content: none;
    }
    &:last-child {
        margin-bottom: 20px;
    }
}

.mc-list-links {

    li {
        @include menu-item-style;
        a {
            @include menu-link-style;
        }
        span {
            @include menu-text-style;
        }
    }
}

.mc-list-social {

    padding: 4px 20px;
    margin: 0;
    margin-bottom:70px;
    &::after {
        content: "";
    }


    li {
        display: inline-block;
        padding:0;
        margin:0;
        &::before{
            content: none;
        }
        &:last-child {
            margin-bottom: 0;
        }

        a {
            font-size: 22px;
            display: block;
            float: left;
            width: 38px;
            height: 38px;
            line-height: 38px;
            border: 1px solid $mc__color__dim-gray;
            background-color: $mc__color__dim-gray;
            color: $white;
            margin-right: 5px;
            border-radius: 50%;
            text-align: center;

            &:hover,
            &:active {
                background-color: $white;
                color: $mc__color__dim-gray;
            }
        }
    }

}