@mixin container-fluid {
    max-width: none;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@mixin before-mobile-mondoconv {
    @media (max-width: 1199px) {
        @content;
    }
}

@mixin after-mobile-mondoconv {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin before-viewport-mondoconv {
    @media (max-width: 1439px) {
        @content;
    }
}

@mixin after-viewport-mondoconv {
    @media (min-width: 1440px) {
        @content;
    }
}


@mixin mobile-xs {
    @media (max-width: 400px) {
        @content;
    }
}

@mixin homepage-slider-full {
    @media (max-width: 1399px) {
        @content;
    }
}
@mixin homepage-slider-xl {
    @media (max-width: 1279px) {
        @content;
    }
}
@mixin homepage-slider-lg {
    @media (max-width: 1023px) {
        @content;
    }
}
@mixin homepage-slider-md {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin linear-gradient( $dir: top, $start-color: #eee, $end-color: #aaa ){
    background: -webkit-linear-gradient($dir, $start-color 0%, $end-color 100%);
    background: -moz-linear-gradient($dir, $start-color 0%, $end-color 100%);
    background: -ms-linear-gradient($dir, $start-color 0%, $end-color 100%);
    background: -o-linear-gradient($dir, $start-color 0%, $end-color 100%);
    background: linear-gradient($dir, $start-color 0%, $end-color 100%);
}

@mixin product-gradient() {
    position: absolute;
    width: 100%;
    max-height: 240px;
    content: "";
    left: 0;
    z-index: -1;
    @include linear-gradient(0deg, #fff, #f3f3f3);
}

@mixin custom-popup-styles {
    h2 {
        font: normal normal bold 16px/35px Montserrat;
        line-height: 3.5rem;
        font-weight: $mc__font-weight__bold;
        margin-bottom: 1.2rem;
    }
    h3 {
        font-size: 1.4rem;
        line-height: 3.5rem;
        margin-bottom: 1.2rem;
        font-weight: $mc__font-weight__bold;
        text-align: left;
    }
    h4, p, li, dl, button {
        text-align: left;
        font-size: 1.2rem;
        line-height: 1.6rem;
        margin-bottom: 1.2rem;
    }

    ul>li, ol>li {
        margin-top: 0rem;
        margin-bottom: 0rem;
        &::before {
            content: '-'
        }
    }

    .Image {
        padding-bottom: 62.5%;
    }
}

@mixin custom-close-button {
    &::before {
        background-image: url('#{$mc__asset_public_path}/icons/close.png');
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        top:10px;
        left: 10px;
        border: none;
        transform: none;
        background-color: transparent;
    }
    &::after {
        display: none;
    }
}

@mixin informazioni-aggiuntive-text {
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-family: $mc__font-family__montserrat;

    @include desktop {
        font-weight: $mc__font-weight__lighter;
        width: 60%;
    }
}

@mixin informazioni-aggiuntive-icon {
    &::before {
        width: 30px;
        text-align: center;
        position: static;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        content: "\f05a";
        margin-right: 10px;
    }
}

@mixin informazioni-aggiuntive-item {
    font-size: 1.6rem;
    line-height: 1.9rem;
    margin-top: 14px;
    font-family: $mc__font-family__montserrat;

    @include desktop {
        font-weight: $mc__font-weight__lighter;
    }
    &::before {
        width: 30px;
        text-align: center;
        position: static;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        content: "\f05a";
        margin-right: 10px;
        margin-top: 14px;
    }
}

@mixin mc-overlay {

    box-shadow: 0px 10px 20px #00000026;
    border: 1px solid $mc__color__whisper;
    background: $white 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 25px;
    margin-top: 20px;

    &::before {
        content: '';
        position: absolute;
        top: -8px;
        height: 15px;
        width: 15px;
        transform: rotate(45deg);
        background: $white 0% 0% no-repeat padding-box;
        border-top: 1px solid $mc__color__whisper;
        border-left: 1px solid $mc__color__whisper;

    }


    &_size {
        &_small {
            width:600px;
            left:-400px;
            &::before {
                left:470px;
            }

            @include before-mobile-mondoconv {
                left:0;
                &::before {
                    left: 70px;
                }
            }
            font-size: 1rem;

            @include custom-popup-styles;


        }
    }

}

@mixin mc-catas {
    background-image: url('../../../public/icons/catas_medium.png');
    height: 46px;
    width: 86px;
    &_isSmall {
        height: 46px;
        width: 86px;
        background-image: url('../../../public/icons/catas_medium.png');
    }
}

@mixin mc-cosmob {
    background-image: url('../../../public/icons/it-cosmob.png');
    height: 57px;
    width: 85px;
    &_isSmall {
        height: 57px;
        width: 85px;
        background-image: url('../../../public/icons/it-cosmob.png');
    }
}

@mixin mc-cosmob-reti {
    background-image: url('../../../public/icons/cosmob-big.png');
    height: 57px;
    width: 85px;
    background-repeat: no-repeat;
    &_isSmall {
        height: 57px;
        width: 85px;
        background-image: url('../../../public/icons/cosmob-small.png');
        background-repeat: no-repeat;
    }
}

@mixin mc-catas-reti {
    background-image: url('../../../public/icons/reti_catas_big.png');
    height: 35px;
    width: 78px;
    background-repeat: no-repeat;
    &_isSmall {
        height: 57px;
        width: 85px;
        background-image: url('../../../public/icons/reti_catas_medium.png');
        background-repeat: no-repeat;
    }
}

@mixin mc-dormibed-reti {
    background-image: url('../../../public/icons/dormibed-big.png');
    height: 57px;
    width: 100px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-size: 85px;
    &_isSmall {
        height: 57px;
        width: 100px;
        margin-right: 10px;
        background-image: url('../../../public/icons/dormibed-small.png');
        background-repeat: no-repeat;
        background-size: 85px;
    }
}
