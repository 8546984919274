.DolceCasa {
    &-Wrapper {
        max-width: $mc__width__boxed;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    &-BannerMobile {
        &.Image_ratio_square {
            padding-bottom: 37%;
        }

        @include desktop {
            display: none !important;
        }
    }

    &-Banner {
        display: none !important;

        &.Image_ratio_square {
            padding-bottom: 28%;
        }

        @include desktop {
            display: block !important;
        }
    }

    &-h1 {
        font-family: $mc__font-family__raleway;
        text-transform: uppercase;
        text-align: center;
        font-weight: $mc__font-weight__lighter;
        color: $mc__color__night-rider;
        font-size: 20px;
        line-height: 20px;
        margin-top: 4rem;

        span {
            text-align: center;
            font-weight: $mc__font-weight__bold;
            letter-spacing: 0px;
            color: $mc_color_dolcecasa;
        }

        @include desktop {
            display: none;
        }
    }

    &-Servizio {
        margin-bottom: 36px;
        font-size: 22px;
        line-height: 30px;
        text-transform: initial;
        text-align: center;
        font-weight: $mc__font-weight__bold;
    }

    &-icon-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-row-gap: 45px;
        text-align: center;
        margin: 50px auto;

        @include mobile {
            grid-template-columns: 1fr 1fr;
        }

        .Image_ratio_square {
            padding-bottom: 50%;
        }

        img {
            max-width: 100%;
            height: auto;
            border: 0;
        }
    }

    &-domanda {
        margin-left: 10px;
        margin-right: 10px;
        text-align: left;
        font: $mc__font-weight__bold 25px $mc__font-family__raleway;
        letter-spacing: 0px;
        color: $mc_color_dolcecasa;
        margin-top: 1.5rem;
        margin-bottom: 1rem;
    }

    &-risposta {
        margin-left: 10px;
        margin-right: 10px;
        font-size: 14px;
        line-height: 29px;
        margin-bottom: 35px;
        font-family: $mc__font-family__montserrat;
    }

    &-mestiere-chiama {
        text-align: left;
        font: $mc__font-weight__bold 33px $mc__font-family__raleway;
        letter-spacing: 0px;
        color: $mc__color__night-rider;
        margin: 30px 0;
        display: inline-block;

        @include mobile {
            font-size: 25px;
        }
    }

    &-numero.Image {
        max-width: 200px;
        margin-left: 2rem;
        padding-bottom: 10%;
    }

    &-chiamaWrapper {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        margin-top: 2rem;
        margin-left: 1rem;
    }

    &-grey {
        background: $mc__color__white-smoke;
    }

    &-content-bottom {
        .Image {
            padding-bottom: 37%;
        }
        h3 {
            text-align: center;
            font: $mc__font-weight__bold 40px $mc__font-family__raleway;
            letter-spacing: 0px;
            color: $mc__color__night-rider;
            margin: 30px;
        }
    }

    &-testimonial {
        .Image {
            padding-bottom: 25%;
        }
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        padding-bottom: 4rem;

        @include mobile {
            grid-template-columns: 1fr;
            padding-bottom: 8rem;
        }
    }

    &-testimonial-desk.Image {
        @include mobile {
            display: none;
        }
    }

    &-testimonial-mobile.Image {
        @include desktop {
            display: none;
        }
    }

    .Form {
        background-color: $mc_color_dolcecasa;
        padding: 15px 15px 24px 15px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
}
