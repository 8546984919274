
$white: #fff;
$black: #0a0a0a;
$default-primary-base-color: #333333;
$default-primary-dark-color: #db230b;
$default-primary-light-color: #666666;
$default-secondary-base-color: #eee;
$default-secondary-dark-color: #333333;
$default-secondary-light-color: #f8f8f8;

$font-standard-size: 62.5%;

$mc__bp-sm: 576px;
// $mc__bp-md: 992px;
// $mc__bp-lg: 1200px;

$mc__color__night-rider: #333333;
$mc__color__venetian-red: #db230b;
$mc__color__white-smoke: #f2f2f2;
$mc__color__gainsboro: #d8d8d8;
$mc__color__whisper: #e7e7e7;
$mc__color__dim-gray: #666666;
$mc__color__grey66: #a8a8a8;

$mc__color__grey60: #999999;

$mc__color__mariner: #306dd5;
$mc__color__anakiwa: #91cffc;
$mc__color_blackfriday: #112738;
$mc__color__countdown_secondary: #D09298;
$mc__color__countdown_primary: #D3202E;

$mc_color_dolcecasa: #aacfbd; // TODO aggiungere alla styleguide
$mc_color_green: #006400; // TODO aggiungere alla styleguide

$mc__grey__background-color: #E2E2E2;

$mc__primary-button__background-color: #152C69;
$mc__primary-button__color: #F6F6F6;
$mc__primary-button-hover__background-color: #0F1E46;

$mc__outline-button-hover__background-color: #F2F2F2;

$mc__danger-button__background-color: #DB230B;
$mc__danger-button__color: white;
$mc__danger-button-hover__background-color: #DB230B;

$mc__color__notification-success--color: #155724;
$mc__color__notification-success--bg: #D4EDDA;
$mc__color__notification-success--border: #C3E6CB;

$mc__color__notification-error--color: #721C24;
$mc__color__notification-error--bg: #F8D7DA;
$mc__color__notification-error--border: #F5C6CB;

$mc__color__notification-info--color: #856404;
$mc__color__notification-info--bg: #FFF3CD;
$mc__color__notification-info--border: #FFEEBA;

$mc__font-family__cabin: 'Cabin', sans-serif;
$mc__font-family__raleway: 'Raleway', sans-serif;
$mc__font-family__roboto: 'Roboto', sans-serif;
$mc__font-family__yeseva: 'Yeseva One', cursive;
$mc__font-family__montserrat: 'Montserrat', sans-serif;;

$mc__asset_public_path: "../../../public";

$mc__width__boxed: 1200px;
$mc__width__product-page: 1400px;
$mc__width__category:1440px;

$font-muli: $mc__font-family__montserrat;

$mc__promo_popup_height_mobile: 70px;
$mc__promo_popup_height: 35px;

/**
* Typography
**/
$mc__font-weight__lighter: 300;
$mc__font-weight__regular: 400;
$mc__font-weight__medium: 500;
$mc__font-weight__semibold: 600;
$mc__font-weight__bold: 700;

$mc__h1__font-size: 10px;
$mc__h1__margin-top: 0;
$mc__h1__margin-bottom: 0;

$mc__h2__font-size: 10px;
$mc__h2__margin-top: 0;
$mc__h2__margin-bottom: 0;

$mc__h3__font-size: 10px;
$mc__h3__margin-top: 0;
$mc__h3__margin-bottom: 0;

$mc__h4__font-size: 10px;
$mc__h4__margin-top: 0;
$mc__h4__margin-bottom: 0;

$mc__h__font-size: 10px;
$mc__h__margin-top: 0;
$mc__h__margin-bottom: 0;
