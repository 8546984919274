@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/msp/mixin';

.CountdownTimer {
    &_type {
        &_reel {
            position: absolute;
            z-index: 2;
            bottom: 8.5vw;
            left: 54.1vw;
            width: 33.5vw;
            display: flex;
            justify-content: space-around;
            @include before-mobile-mondoconv {
                bottom: 29.5vw;
                left: 20vw;
                width: 59.3vw;
            }
            .CountdownTimer {
                &-Item {
                    font-size: 3.5vw;
                    flex:1;
                    color: $mc__color__white-smoke;  
                    text-align: center;      
                    font-family: 'Elain ITC';
                    @include before-mobile-mondoconv {
                        font-size: 6.5vw;
                    }
                }
            }
        }

        &_topnav {
            display: inline-flex;
            flex-direction: row;
            .CountdownTimer {
                &-Item {
                    margin-left: 5px;
                }
            }
        }
    }
}