:root {
    --button-padding: 16px 10px;
    --button-color: #{$mc__primary-button__color};
    --button-background: #{$mc__primary-button__background-color};
    --button-border: #{$mc__primary-button__background-color};
    --button-hover-background: #{$mc__primary-button-hover__background-color};
    --button-hover-color: #{$mc__primary-button__color};
    --button-hover-border: #{$mc__primary-button-hover__background-color};
    --button-border-width: 1px;
}

.Button {
    font-family: $mc__font-family__montserrat;
    box-shadow: 0px 7px 15px #0000003D !important;
    border-radius: 24px !important;
    &_isOutline {
        border-color: white;
        color: $mc__primary-button__background-color;
        background: white;

        &:not([disabled]) {
            &:hover,
            &:focus {
                @include after-mobile {
                    border-color: $mc__outline-button-hover__background-color;
                    background: $mc__outline-button-hover__background-color;
                    color: $mc__primary-button__background-color;
                }
            }
        }
    }

    &_isDanger {
        border-color: $mc__danger-button__background-color;
        color: $mc__danger-button__color;
        background: $mc__danger-button__background-color;

        &:not([disabled]) {
            &:hover,
            &:focus {
                @include after-mobile {
                    border-color: $mc__danger-button__background-color;
                    background: $mc__danger-button__background-color;
                }
            }
        }
    }

    &_isDangerOutline {
        border-color: white;
        color: $mc__danger-button__background-color;
        background: white;

        &:not([disabled]) {
            &:hover,
            &:focus {
                @include after-mobile {
                    border-color: $mc__outline-button-hover__background-color;
                    background: $mc__outline-button-hover__background-color;
                    color: $mc__danger-button__background-color;
                }
            }
        }
    }

    &_isSearchRounded {
        border-radius: 23px;

        &:not([disabled]) {
            &:hover,
            &:focus {
                @include after-mobile {
                    border-color: $mc__primary-button__background-color;
                    background: $mc__primary-button__background-color;
                    color: $mc__primary-button__color;
                }
            }
        }
    }
    &_likeLink {
        border-radius: 0;
        box-shadow: none;
    }
}
