@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.SearchResultCategory {
    font-size: 13px;
    display: block;
    padding: 7px;
    margin-bottom: -7px;

    &-productCount {
        margin-left: 5px;
    }
}
