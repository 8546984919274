@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.Field {
    &_hasError {
        input,
        textarea {
            border: 2px solid var(--primary-error-color);
        }
    }

    &_isValid {
        input,
        textarea {
            border: 2px solid var(--primary-success-color);
        }
    }
}