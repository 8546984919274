@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/msp/menu';


.McMenuDropdown {
    &-Link {
        @include menu-link-style;
    }


    &-Text {
        @include menu-text-style;
    }


    
    &-Menu {
        background-color: #FFF;
        min-width: 280px;
        padding: 20px;
        box-shadow: 0px 10px 20px #00000026;
        line-height: 2rem;
        position: absolute;
        top: 100%;
        right: 0;

        &_isSubDropdown {
            left: auto;
            right: 100%;
            top: -20px;
            margin-right: 20px;
        }

        li {
            &::before{
                content: '';
            }
            padding:0;
            &:last-child {
                margin-bottom:0;
            }
        }
    }

    &-Item{
        width: 100%;
        height: 35px;
        cursor: pointer;
        @include menu-category-style;
    }
}