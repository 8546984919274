@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.BannerModal {
    &-Wrapper {
        padding-top:40px;
        padding-bottom: 40px;
        @include mobile {
            padding: 15px 0;
        }
        
        &_type {
            &_promo {
                padding-top:0;
            }
        }

    }
    &-Banner {
        padding-bottom:31%;
        cursor: pointer;
    }
}