// @import url('https://fonts.googleapis.com/css2?family=Cabin:wght@500&family=Raleway:wght@600;700&family=Roboto:wght@400;500;700&display=swap');

h1,
.h1 {
    font-family: $mc__font-family__raleway;
    font-size: 4rem;
    font-weight: $mc__font-weight__bold;
    line-height: 5.4rem;
    color: $mc__color__night-rider;
    margin-top: $mc__h3__margin-top;
    margin-bottom: $mc__h3__margin-bottom;
}

h2,
.h1 {
    font-family: $mc__font-family__raleway;
    font-size: 3rem;
    font-weight: $mc__font-weight__semibold;
    line-height: 3.5rem;
    color: $mc__color__night-rider;
    margin-top: $mc__h3__margin-top;
    margin-bottom: $mc__h3__margin-bottom;
}

h3 {
    font-family: $mc__font-family__montserrat;
    font-size: 2.1rem;
    font-weight: $mc__font-weight__medium;
    line-height: 2.6rem;
    color: $mc__color__night-rider;
    margin-top: $mc__h3__margin-top;
    margin-bottom: $mc__h3__margin-bottom;
}

h4 {
    font-family: $mc__font-family__montserrat;
    font-size: 1.8rem;
    font-weight: $mc__font-weight__medium;
    line-height: 2.3rem;
    color: $mc__color__night-rider;
    margin-top: $mc__h3__margin-top;
    margin-bottom: $mc__h3__margin-bottom;
}

h5 {
    font-family: $mc__font-family__montserrat;
    font-size: 1.8rem;
    font-weight: $mc__font-weight__medium;
    line-height: 2.8rem;
    color: $mc__color__night-rider;
    margin-top: $mc__h3__margin-top;
    margin-bottom: $mc__h3__margin-bottom;
}

p {
    font-family: $mc__font-family__montserrat;
    font-size: 1.6rem;
    font-weight: $mc__font-weight__regular;
    line-height: 2.4rem;
    color: $mc__color__night-rider;

    .small {
        font-size: 1.4rem;
        line-height: 2.3rem;
    }
}

